import { useEffect, useMemo, useState } from 'react';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import { buyerAndSupplierValidators } from '@odo/screens/deal/editor/buyer-and-supplier';
import { productValidators } from '@odo/screens/deal/editor/product';
import { conditionsAndCategoryValidators } from '@odo/screens/deal/editor/conditions-and-category';
import { priceAndCustomOptionsValidators } from '@odo/screens/deal/editor/price-and-custom-options';
import { shippingAndInventoryValidators } from '@odo/screens/deal/editor/shipping-and-inventory';
import { imagesAndVideosValidators } from '@odo/screens/deal/editor/images-and-videos';
import { summaryValidators } from '@odo/screens/deal/editor/summary';

const useValidity = () => {
  const currentProduct = useCurrentProduct();

  const validators = useMemo(
    () => [
      ...buyerAndSupplierValidators,
      ...productValidators,
      ...conditionsAndCategoryValidators,
      ...priceAndCustomOptionsValidators,
      ...shippingAndInventoryValidators,
      ...imagesAndVideosValidators,
      ...summaryValidators,
    ],
    []
  );

  const [validity, setValidity] = useState(() =>
    validateProduct(currentProduct, validators)
  );

  /**
   * Re-run validation on any changes.
   */
  useEffect(() => {
    setValidity(validateProduct(currentProduct, validators));
  }, [currentProduct, validators]);

  return validity;
};

export default useValidity;
