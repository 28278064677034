import type { ButtonProps } from '@odo/components/elements/button';
import Button from '@odo/components/elements/button';
import type { AnchorHTMLAttributes } from 'react';
import { FaExternalLinkAlt as IconExternalLink } from 'react-icons/fa';

const ButtonLink = ({
  href,
  target,
  children,
  ...rest
}: {
  href: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
} & ButtonProps) => (
  <a
    href={href}
    target={target}
    style={{ all: 'unset' }}
    rel={target === '_blank' ? 'noopener noreferrer' : undefined}
  >
    <Button {...rest}>
      {children}
      <IconExternalLink color="inherit" />
    </Button>
  </a>
);

export default ButtonLink;
