import { useMemo } from 'react';
import { useProductEditor } from '@odo/contexts/product-editor';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';

// TODO: BP-664: move this hook to our product editor context when custom options editor is dropped
const useHasChanges = () => {
  const { hasChanges: hasProductChanges } = useProductEditor();

  const { hasUnsavedActions: hasCustomOptionChanges } =
    useCustomOptionsEditorContext();

  const hasChanges = useMemo(
    () => hasProductChanges || hasCustomOptionChanges,
    [hasProductChanges, hasCustomOptionChanges]
  );

  return hasChanges;
};

export default useHasChanges;
