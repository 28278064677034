import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import { useProductEditor } from '@odo/contexts/product-editor';
import { useDealId } from '@odo/screens/deal/editor/hooks';
import { useEffect } from 'react';

const PreventLosingChanges = () => {
  const { isDraft } = useDealId();
  const { hasChanges } = useProductEditor();
  const { hasUnsavedActions } = useCustomOptionsEditorContext();

  useEffect(() => {
    // we don't need to prevent leaving if the deal is a draft as that data is cached.
    if (isDraft || (!hasChanges && !hasUnsavedActions)) return;

    const confirmLeaving = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return 'You will lose any unsaved changes to this deal.';
    };

    window.addEventListener('beforeunload', confirmLeaving);
    return () => window.removeEventListener('beforeunload', confirmLeaving);
  }, [isDraft, hasChanges, hasUnsavedActions]);

  return null;
};

export default PreventLosingChanges;
