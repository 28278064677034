import { Input, Select, Switch } from '@odo/components/elements/form-fields';
import { Box, Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import {
  SKU_FIELD_ID,
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import {
  informationSectionValidators,
  sizeInfoSectionValidators,
} from '@odo/screens/deal/editor/product/validators';
import { cssColor } from '@odo/utils/css-color';
import { useState, useMemo } from 'react';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import TextEditor from '@odo/components/widgets/text-editor';
import { ACCEPTED_IMAGE_TYPES } from '@odo/screens/deal/editor/constants';
import {
  ActiveDateTime,
  SizeInfoImage,
} from '@odo/screens/deal/editor/product/widgets';
import { isSizeInfoImageLoaded } from '@odo/screens/deal/editor/product/helpers';
import SkuStatus from '@odo/screens/deal/editor/widgets/sku-status';

const ProductInformationSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    informationSectionValidators
  ).status;

  return (
    <SectionWrapper title="Product Information" status={status}>
      <Switch
        label="Deal enabled"
        width="3.5em"
        checked={!!currentProduct.status}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'status',
            label: 'Deal enabled',
            apply: to => (to.status = checked),
          });
        }}
      />

      <Switch
        label="Preview only"
        width="3.5em"
        checked={!!currentProduct.isPreviewOnly}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'isPreviewOnly',
            label: 'Preview only',
            apply: to => (to.isPreviewOnly = checked),
          });
        }}
      />

      <Input
        label="Brand"
        value={currentProduct.brand || ''}
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'brand',
            label: 'Brand',
            apply: to => (to.brand = value),
          });
        }}
        required
        selectOnFocus
      />

      <Input
        label="Name"
        value={currentProduct.name || ''}
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'name',
            label: 'Name',
            apply: to => (to.name = value),
          });
        }}
        required
        selectOnFocus
      />

      <Input
        label="Short Name"
        value={currentProduct.shortName || ''}
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'shortName',
            label: 'Short Name',
            apply: to => (to.shortName = value),
          });
        }}
        required
        selectOnFocus
      />

      {/* SKU and it's status */}
      <Flex flexDirection="column" gap={1}>
        <Input
          label="SKU"
          autoComplete="off"
          value={currentProduct.sku || ''}
          onChange={e => {
            const value = e.target.value;
            change({
              fieldId: SKU_FIELD_ID,
              label: 'SKU',
              apply: to => (to.sku = value),
            });
          }}
          required
          selectOnFocus
        />

        <Box pl={1}>
          <SkuStatus />
        </Box>
      </Flex>

      <Input
        label="URL Key"
        value={currentProduct.url || ''}
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'url',
            label: 'URL Key',
            apply: to => (to.url = value),
          });
        }}
        required
        selectOnFocus
      />

      <ActiveDateTime />
    </SectionWrapper>
  );
};

const ProductDescriptionSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  return (
    <SectionWrapper title="Product Description">
      <Input
        label="Pill 1"
        value={currentProduct.pillOne || ''}
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'pillOne',
            label: 'Pill 1',
            apply: to => (to.pillOne = value),
          });
        }}
        autoComplete="on"
        selectOnFocus
      />

      <Input
        label="Pill 2"
        value={currentProduct.pillTwo || ''}
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'pillTwo',
            label: 'Pill 2',
            apply: to => (to.pillTwo = value),
          });
        }}
        autoComplete="on"
        selectOnFocus
      />

      <TextEditor
        label="Description"
        value={currentProduct.moreDetails || ''}
        onChange={html => {
          change({
            fieldId: 'moreDetails',
            label: 'Product Description',
            apply: to => (to.moreDetails = html),
          });
        }}
      />
    </SectionWrapper>
  );
};

const ProductSizeInfoSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    sizeInfoSectionValidators
  ).status;

  const hasSizeInfo = useMemo(
    () =>
      !!currentProduct.sizeInfo?.measurement?.id ||
      !!currentProduct.sizeInfo?.recommendation ||
      isSizeInfoImageLoaded(currentProduct.sizeInfo?.mobile) ||
      isSizeInfoImageLoaded(currentProduct.sizeInfo?.tablet) ||
      isSizeInfoImageLoaded(currentProduct.sizeInfo?.desktop),
    [
      currentProduct.sizeInfo?.measurement?.id,
      currentProduct.sizeInfo?.recommendation,
      currentProduct.sizeInfo?.mobile,
      currentProduct.sizeInfo?.tablet,
      currentProduct.sizeInfo?.desktop,
    ]
  );

  const [isCollapsed, setIsCollapsed] = useState(!hasSizeInfo);

  const sizeChartMeasurementOptions = useAttributeOptions(
    AttributeCode.sizeChartMeasurements
  );

  return (
    <SectionWrapper
      title="Size Info"
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      disableCollapse={hasSizeInfo}
      status={status}
    >
      <Select
        label="How To Measure Guide"
        value={currentProduct.sizeInfo?.measurement?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const measurement = sizeChartMeasurementOptions.find(
            option => option.originalData.value === value
          );
          change({
            fieldId: 'sizeInfo.measurement',
            label: 'Size Info - How To Measure Guide',
            apply: to =>
              (to.sizeInfo = {
                ...to.sizeInfo,
                measurement: measurement
                  ? {
                      id: measurement.originalData.value,
                      label: measurement.label,
                    }
                  : undefined,
              }),
          });
        }}
        options={[
          ...sizeChartMeasurementOptions.map(option => ({
            id: option.originalData.value,
            value: option.originalData.value,
            label: option.label,
          })),
        ]}
      />

      <Grid gap={2}>
        <Grid
          gridTemplateColumns="repeat(auto-fill, minmax(189px, 1fr))"
          gap={[2, 3]}
        >
          <SizeInfoImage image={currentProduct.sizeInfo.desktop} />
          <SizeInfoImage image={currentProduct.sizeInfo.tablet} />
          <SizeInfoImage image={currentProduct.sizeInfo.mobile} />
        </Grid>

        <Text
          pl={2}
          color={cssColor('grey')}
          textAlign="left"
          fontStyle="italic"
        >
          Accepted file types: {ACCEPTED_IMAGE_TYPES.join(', ')}
        </Text>
      </Grid>

      <TextEditor
        label="Recommendation"
        value={currentProduct.sizeInfo?.recommendation || ''}
        onChange={html => {
          change({
            fieldId: 'sizeInfo.recommended',
            label: 'Size Info - Recommendation',
            apply: to =>
              (to.sizeInfo = { ...to.sizeInfo, recommendation: html }),
          });
        }}
      />
    </SectionWrapper>
  );
};

const ProductScreen = () => (
  <ErrorBoundary>
    <Grid
      gridTemplateColumns={['1fr', '1fr 1fr']}
      gridTemplateRows={['auto', 'auto 1fr']}
      gap={[3, 4]}
      alignItems="flex-start"
    >
      <GridItem gridColumn="1">
        <ProductInformationSection />
      </GridItem>

      <GridItem gridColumn={['1', '2']} gridRow={['2', '1/3']}>
        <ProductDescriptionSection />
      </GridItem>

      <GridItem gridColumn="1">
        <ProductSizeInfoSection />
      </GridItem>
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default ProductScreen;
