import React, { useState, useRef, useEffect } from 'react';
import { useSafeEffect } from 'hooks/useSafeEffect';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { usePreviousValue } from 'hooks/usePreviousValue';
import { Deal } from 'models/Deal.jsx';
import {
  RPSCard,
  RPSListContainer,
} from '@rps/web-components/build/react-wrappers';
import { DealProduct } from 'models/Product.jsx';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './css/TextEditor.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import textOverrides from './css/TextOverrides.module.css';
import { Input } from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';

export const ProductDescriptionSection = () => {
  const currentDeal = useCurrentDealSource();
  const model = currentDeal.deal.product;
  const [localEditorState, setLocalEditorState] = useState();

  const editorRef = useRef();

  const previousDescriptionValue = usePreviousValue(model.moreDetails);

  useSafeEffect(() => {
    if (previousDescriptionValue === ' ' || !previousDescriptionValue) {
      if (editorRef.current) {
        editorRef.current.value = model.moreDetails;
        editorRef.current.content = model.moreDetails;
      }
    }
  }, [currentDeal.deal, editorRef.current]);

  useEffect(() => {
    if (localEditorState || !model.moreDetails || model.moreDetails === ' ') {
      return;
    }
    const contentBlock = htmlToDraft(model.moreDetails);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setLocalEditorState(EditorState.createWithContent(contentState));
    }
  }, [localEditorState, model.moreDetails]);

  const handleEditorStateChange = editorState => {
    const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setLocalEditorState(editorState);
    currentDeal.deal.set(
      Deal.MODELS.PRODUCT,
      DealProduct.FIELDS.DESCRIPTION,
      newValue
    );
    currentDeal.update();
  };

  const blockFormatter = contentBlock => {
    const type = contentBlock.getType();
    if (type === 'header-five') {
      return textOverrides.productDescriptionH5;
    }
  };

  const styleMap = {
    H5: {
      textDecoration: 'line-through',
    },
  };

  const editorToolbarOptions = {
    options: [
      'inline',
      'blockType',
      'list',
      'textAlign',
      'fontFamily',
      'fontSize',
      'link',
      'remove',
      'history',
    ],
    inline: {
      options: ['bold', 'underline', 'italic', 'monospace', 'superscript'],
    },
    blockType: {
      options: ['Normal', 'H5', 'Blockquote', 'Code'],
    },
    fontSize: {
      options: [10, 11, 12, 14, 15, 16, 17, 18],
    },
    fontFamily: {
      options: ['Open Sans', 'Montserrat', 'sans-serif'],
    },
    link: {
      defaultTargetOption: '_blank',
    },
  };

  return (
    <RPSCard>
      <div slot="header">
        <h5>Product Description</h5>
      </div>
      <RPSListContainer className="vertical">
        <Input
          label="Pill 1"
          value={currentDeal.deal.product.pillOne}
          onChange={handleODOInput({
            currentDeal,
            model: Deal.MODELS.PRODUCT,
            field: DealProduct.FIELDS.PILL1,
          })}
          autoComplete="on"
          selectOnFocus
          matchRPStyles
        />

        <Input
          label="Pill 2"
          value={currentDeal.deal.product.pillTwo}
          onChange={handleODOInput({
            currentDeal,
            model: Deal.MODELS.PRODUCT,
            field: DealProduct.FIELDS.PILL2,
          })}
          autoComplete="on"
          selectOnFocus
          matchRPStyles
        />

        <Editor
          blockStyleFn={blockFormatter}
          editorState={localEditorState}
          wrapperClassName="react-draft-wrapper"
          editorClassName="react-draft-editor"
          onEditorStateChange={handleEditorStateChange}
          toolbarStyle={{
            borderBottom: '1px solid var(--component-primary)',
          }}
          customStyleMap={styleMap}
          toolbar={editorToolbarOptions}
        />
      </RPSListContainer>
    </RPSCard>
  );
};
