import { isProductDateLive } from '@odo/screens/deal/editor/helpers';
import { isSizeInfoImageLoaded } from '@odo/screens/deal/editor/product/helpers';
import { Status, type Validator } from '@odo/screens/deal/editor/types';
import { SkuAvailability } from '@odo/types/portal';

export const informationSectionValidators: Validator[] = [
  {
    validate: product => !!product?.brand,
    message: 'You must enter a brand name.',
    status: Status.error,
  },
  {
    validate: product => !!product?.name,
    message: 'You must enter a product name.',
    status: Status.error,
  },
  {
    validate: product => !!product?.shortName,
    message: 'You must enter a short name.',
    status: Status.error,
  },
  {
    validate: product => !!product?.sku,
    message: 'You must enter an SKU.',
    status: Status.error,
  },
  {
    validate: product => !!product.activeFromDate && !!product.activeToDate,
    message: 'You must choose a from/to date.',
    status: Status.error,
  },
  {
    validate: product => product.skuAvailability === SkuAvailability.taken,
    invertValidation: true,
    message: 'SKU already in use, please select another.',
    status: Status.error,
  },
  {
    validate: product => product.skuAvailability === SkuAvailability.checking,
    invertValidation: true,
    message: "SKU isn't validated yet, please wait.",
    status: Status.warning,
  },
  {
    validate: product =>
      product.skuAvailability === SkuAvailability.validationFailed,
    invertValidation: true,
    message: 'Could not validate SKU, please try again.',
    status: Status.warning,
  },
  {
    validate: product => product.skuAvailability === SkuAvailability.isTemplate,
    invertValidation: true,
    message: 'SKU needs to be updated for new deal.',
    status: Status.error,
  },
  {
    validate: product =>
      !product.activeFromDate ||
      !product.activeToDate ||
      new Date(
        `${product.activeFromDate} ${
          product.isTimedDeal ? product.activeFromTime : '00:00'
        }`
      ) <=
        new Date(
          `${product.activeToDate} ${
            product.isTimedDeal ? product.activeToTime : '23:59'
          }`
        ),
    message: 'From date must be before to date.',
    status: Status.error,
  },
  {
    /**
     * NOTE: we probably want to require some form of confirmation/prompt for ths instead.
     * we don't currently have the best idea of how we'd want that though, so we're gonna do this for now.
     */
    validate: product => !isProductDateLive(product),
    message: 'Dates overlap with today - deal will go live immediately!',
    status: Status.warning,
  },
];

export const descriptionSectionValidators: Validator[] = [];

export const sizeInfoSectionValidators: Validator[] = [
  {
    validate: product =>
      !!product.sizeInfo.measurement?.id ||
      (!isSizeInfoImageLoaded(product.sizeInfo?.mobile) &&
        !isSizeInfoImageLoaded(product.sizeInfo?.tablet) &&
        !isSizeInfoImageLoaded(product.sizeInfo?.desktop)),
    message: 'No size info measurement guide selected.',
    status: Status.warning,
  },
];

const validators: Validator[] = [
  ...informationSectionValidators,
  ...descriptionSectionValidators,
  ...sizeInfoSectionValidators,
];

export default validators;
