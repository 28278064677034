import type { EditorProductInterface } from '@odo/types/portal';
import {
  EMPTY_PRODUCT,
  SKU_FIELD_ID,
  type ProductChange,
} from '@odo/contexts/product-editor';
import validateSku from '@odo/data/product/validate-sku';
import { mutationUpdateProduct, queryProduct } from '@odo/graphql/product-new';
import {
  editorProductToUpdateProductInput,
  getProductToEditorProduct,
} from '@odo/transformers/product';
import { produce } from 'immer';
import { uploadAllNewImages } from '@odo/data/product/images';
import { error } from '@odo/utils/toast';
import { downloadImage } from '@odo/screens/deal/editor/images-and-videos/helpers';
import { loadBreadcrumbs } from '@odo/data/product/category';
import type { Attribute } from '@odo/contexts/attributes';
import { editorSizeInfoToUpdateSizeChartInput } from '@odo/transformers/size-chart';

/**
 * NOTE: The API technically allows sending only the fields that have changed.
 * But our update mutation will send the full product object instead.
 * This is because we have some tricky issues tied to how our changes are tracked and must be updated.
 *
 * One of our requirements is to only update the fields that have been changed by the user,
 * in case other changes have happened since they opened the deal for editing.
 * And in order to do so we track their changes on a per field/property basis.
 * But some of those fields/properties (eg. dealType, campaignMailer, platform, surcharges, categories, etc.),
 * are arrays and need the entire list when updating, else we risk losing individual entries.
 * To support both requirements, we'd need to load these fields
 * and check each for a change before adding them to the update object.
 * While this is technically doable. It would introduce more complexity and maintenance concerns.
 */
const updateProduct = async ({
  id,
  changes,
  attributes,
  signal,
}: {
  id: string;
  changes: ProductChange[];
  attributes: Attribute[];
  signal?: AbortSignal;
}) => {
  // validate the sku if it has changed (findLast just in case we support multiple changes in future)
  const skuChange = [...changes]
    .reverse()
    .find(change => change.fieldId === SKU_FIELD_ID);

  if (skuChange) {
    // we cannot get the new SKU value from the change because it's designed to run an apply function
    // so we need a dummy product object to apply against, and then take the SKU off that
    const dummyProduct: EditorProductInterface = EMPTY_PRODUCT;
    skuChange.apply(dummyProduct, skuChange.meta);
    if (dummyProduct.sku) {
      // will throw it's own error on failure
      await validateSku({ id, sku: dummyProduct.sku });
    }
  }

  // load the latest version of the product data from magento/api
  const product = await queryProduct({ id, signal });
  if (!product) {
    throw new Error(
      'Failed to load product from magento before save for diffing. Please try again in a few.'
    );
  }

  // we need to load the category breadcrumbs so that we can find the category type
  // without the category type we'd lose certain changes made during editing
  const breadcrumbs = await loadBreadcrumbs({ categories: product.categories });

  // transform raw data to our editor interface
  const editorProduct = getProductToEditorProduct({
    product,
    breadcrumbs,
    attributes,
  });
  if (!editorProduct) {
    throw new Error('API product data is invalid.');
  }

  // apply our changes to the latest product data
  const draftProduct = produce(editorProduct, nextProduct => {
    changes.forEach(change => change.apply(nextProduct, change.meta));
  });

  // our size chart images need to be parsed, but our input transformer isn't async
  // so quickly prep them here and pass them through
  const sizeChartInput = await editorSizeInfoToUpdateSizeChartInput(
    draftProduct.sizeInfo
  );

  // transform data for update mutation
  const updateProductInput = editorProductToUpdateProductInput(
    draftProduct,
    sizeChartInput
  );
  if (!updateProductInput) {
    throw new Error('Failed to transform product data for update.');
  }

  // make the update product API call
  const updatedProduct = await mutationUpdateProduct({
    id,
    product: updateProductInput,
    signal,
  });
  if (!updatedProduct) {
    throw new Error('Update product mutation failed.');
  }

  // upload any new images that were added
  const { imageUploadSuccesses, imageUploadFailures } =
    await uploadAllNewImages({ id, images: draftProduct.images });

  // add new images to product data
  if (imageUploadSuccesses.length > 0) {
    imageUploadSuccesses.forEach(createdImage => {
      // remove imageTypes from existing images (as these won't have been removed during the update mutation)
      createdImage.imageTypes?.forEach(imageType => {
        updatedProduct.images?.forEach(existingImage => {
          existingImage.imageTypes = existingImage.imageTypes?.filter(
            type => type !== imageType
          );
        });
      });

      if (!updatedProduct.images) {
        updatedProduct.images = [];
      }

      updatedProduct.images.push(createdImage);
    });
  }

  // show a toast with a download button for each image that failed to upload
  // TODO: BP-775: keep new images that failed to upload
  if (imageUploadFailures.length > 0) {
    imageUploadFailures.forEach(failure =>
      error(`Failed to upload image: "${failure.message}"`, {
        messageOptions: {
          action: {
            label: 'Download image to try again',
            callback: () => downloadImage(failure.image),
          },
        },
      })
    );
  }

  // return our resulting/updated product data
  return updatedProduct;
};

export default updateProduct;
