import { cssColor } from '@odo/utils/css-color';
import styled from '@odo/lib/styled';
import type { InputHTMLAttributes } from 'react';
import { useId } from 'react';
import { Flex } from '@odo/components/elements/layout';

const CheckboxLabel = styled.label`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 12px;
  cursor: pointer;
  user-select: none;

  color: ${cssColor('text')};

  & span {
    margin-top: 2px;
    line-height: 1.5em;
    font-weight: 500;
  }

  &.no-label {
    grid-template-columns: auto;
  }
`;

interface CheckboxInputProps {
  size?: number;
  color?: string;
}

/**
 * NOTE: while some of the concepts are shared with the Switch component,
 * they have too many style differences to make it worth giving them a shared ancestor.
 */
const CheckboxInput = styled.input<CheckboxInputProps>`
  /* our css variables */
  --color: ${p => p.color};
  --size: ${p => p.size}px;
  --border-width: calc(var(--size) / 10);
  --border-radius: calc(var(--size) / 4);

  /* input visual reset */
  appearance: none;
  margin: 0;

  cursor: pointer;
  outline-offset: 6px;
  position: relative;

  width: var(--size);
  height: var(--size);
  border: var(--border-width) solid ${cssColor('grey')};
  border-radius: var(--border-radius);
  transform: translateY(calc(var(--size) / 100 * 4));
  background: ${cssColor('foreground')};

  &::before,
  &::after {
    content: '';
    display: inline-block;
    transform: scale(0);
    transition: 60ms transform ease-in-out;
    background-color: CanvasText;
    border-radius: var(--border-radius);
    position: absolute;
    backface-visibility: hidden;
  }

  &::before {
    background: var(--color);
    top: calc(var(--border-width) * -1);
    left: calc(var(--border-width) * -1);
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
  }

  &::after {
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: white;
    clip-path: polygon(0% 55%, 35% 90%, 100% 25%, 87% 12%, 35% 64%, 13% 42%);
  }

  &:checked::before,
  &:checked::after {
    transform: scale(1);
  }
`;

CheckboxInput.defaultProps = {
  type: 'checkbox',
  color: cssColor('palette-blue'),
  size: 18,
};

export type CheckboxProps = CheckboxInputProps &
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
  };

/**
 * NOTE: we extract the `id` from the list of props
 * because we don't want to allow changing it
 * as that would break the labels `htmlFor`
 * and compromise the guaranteed uniqueness of `React.useId`
 *
 * If we ever find that we NEED to be able to override the `id`,
 * then I'd recommend adding a custom `overrideId` prop.
 */
const Checkbox = ({ label, id: _, ...rest }: CheckboxProps) => {
  const id = useId();

  return (
    <Flex>
      <CheckboxLabel htmlFor={id} className={!label ? 'no-label' : undefined}>
        <CheckboxInput id={id} name={`checkbox-${id}`} {...rest} />
        {label && <span>{label}</span>}
      </CheckboxLabel>
    </Flex>
  );
};

export default Checkbox;
