import type { InputHTMLAttributes, OptionHTMLAttributes } from 'react';
import { useId, useState } from 'react';
import {
  InputLabel,
  SelectLabelWrapper,
  SelectFieldWrapper,
  SelectField,
  ErrorMessage,
} from './shared-styles';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  required?: boolean;
  error?: string;
  options: (Pick<
    OptionHTMLAttributes<HTMLOptionElement>,
    'value' | 'disabled'
  > & {
    id: string;
    label: string;
  })[];
  /**
   * NOTE: for now we're gonna use our components alongside RPs in a few places.
   * While doing so (especially when they are directly next to each other),
   * it make sense to match RPs styling a little bit.
   *
   * TODO: BP-534 remove all styles and code for this once we know we won't be sharing screen real-estate
   */
  matchRPStyles?: boolean;
}

/**
 * Throwing together a quick version coz I just need to replace a RedPanda web component.
 */
const Select = ({
  label,
  required,
  onFocus,
  onBlur,
  error,
  matchRPStyles,
  disabled,
  options,
  ...restProps
}: SelectProps) => {
  const id = useId();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <SelectLabelWrapper>
      <InputLabel
        htmlFor={id}
        className={[
          ...(isFocused ? ['focused'] : []),
          ...(error ? ['error'] : []),
        ].join(' ')}
      >
        {label}
        {required && <span>*</span>}
      </InputLabel>

      <SelectFieldWrapper>
        <SelectField
          id={id}
          name={id}
          className={[
            ...(isFocused ? ['focused'] : []),
            ...(error ? ['error'] : []),
            ...(matchRPStyles ? ['match-rp-styles'] : []),
          ].join(' ')}
          onFocus={e => {
            setIsFocused(true);
            onFocus && onFocus(e);
          }}
          onBlur={e => {
            setIsFocused(false);
            onBlur && onBlur(e);
          }}
          disabled={disabled}
          {...restProps}
        >
          {options.map(option => (
            <option
              key={option.id}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </SelectField>
      </SelectFieldWrapper>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SelectLabelWrapper>
  );
};

export default Select;
