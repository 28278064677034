import type * as CSS from 'csstype';
import { system } from '@styled-system/core';

// eslint-disable-next-line no-restricted-imports
import type { ResponsiveValue, RequiredTheme, Theme } from 'styled-system';

export interface PlaceContentProps<ThemeType extends Theme = RequiredTheme> {
  /**
   * The place-content CSS shorthand property allows you to align content along both the block and inline directions
   * at once (i.e. the align-content and justify-content properties) in a relevant layout system
   * such as Grid or Flexbox.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/place-content)
   */
  placeContent?:
    | ResponsiveValue<CSS.Property.PlaceContent, ThemeType>
    | undefined;
}

export interface PlaceItemsProps<ThemeType extends Theme = RequiredTheme> {
  /**
   * The CSS place-items shorthand property allows you to align items along both the block and inline directions
   * at once (i.e. the align-items and justify-items properties) in a relevant layout system
   * such as Grid or Flexbox. If the second value is not set, the first value is also used for it.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/place-items)
   */
  placeItems?: ResponsiveValue<CSS.Property.PlaceItems, ThemeType> | undefined;
}

export const placeContent = system({
  placeContent: { property: 'placeContent' },
});

export const placeItems = system({
  placeItems: { property: 'placeItems' },
});
