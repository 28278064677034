import { Box } from '@odo/components/elements/layout';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import { cssColor } from '@odo/utils/css-color';
import type { ReactNode } from 'react';
import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  dropTargetForExternal,
  monitorForExternal,
} from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import {
  containsFiles,
  getFiles,
} from '@atlaskit/pragmatic-drag-and-drop/external/file';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { autoScrollWindowForExternal } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/external';

export interface FileDropzoneRef {
  click: HTMLInputElement['click'];
}

interface FileDropzoneProps {
  children: ReactNode;
  acceptedTypes: string[];
  onFileDrop: (files: File[]) => void;
  multiple?: boolean;
}

const FileDropzone = forwardRef<FileDropzoneRef, FileDropzoneProps>(
  ({ children, acceptedTypes, onFileDrop, multiple }, ref) => {
    const dropTargetRef = useRef<HTMLDivElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [uploadDragActive, setUploadDragActive] = useState(false);

    /**
     * NOTE: we only want to expose the click method of our file input.
     */
    useImperativeHandle(
      ref,
      () => ({ click: () => fileInputRef.current?.click() }),
      []
    );

    /**
     * The built-in window auto-scrolling works, but it requires too much precision from users.
     * Using this custom function gives much more user friendly results by scrolling when you get close to the edge.
     */
    useEffect(() => {
      return autoScrollWindowForExternal();
    }, []);

    /**
     * Monitor drag and drop events for files (filtered by acceptedTypes).
     */
    useEffect(() => {
      if (!dropTargetRef.current) return;

      return combine(
        dropTargetForExternal({
          element: dropTargetRef.current,
          canDrop: containsFiles,
          onDragEnter: () => setUploadDragActive(true),
          onDragLeave: () => setUploadDragActive(false),
          onDrop: ({ source }) => {
            setUploadDragActive(false);

            const files = getFiles({ source }).filter(file =>
              acceptedTypes.includes(file.type)
            );
            if (files && files.length > 0) onFileDrop(files);
          },
        }),
        monitorForExternal({
          canMonitor: containsFiles,
          onDragStart: () => preventUnhandled.start(),
          onDrop: () => {
            setUploadDragActive(false);
            preventUnhandled.stop();
          },
        })
      );
    }, [acceptedTypes, onFileDrop]);

    return (
      <Box
        ref={dropTargetRef}
        p="2px"
        borderRadius="12px"
        bg={
          uploadDragActive
            ? cssColor('palette-blue-muted')
            : cssColor('background')
        }
        style={{ transition: 'background 75ms ease' }}
      >
        <Box
          borderRadius="12px"
          borderWidth="2px"
          borderStyle="dashed"
          borderColor={
            uploadDragActive ? cssColor('palette-blue') : cssColor('grey-light')
          }
          style={{ transition: 'border-color 75ms ease' }}
        >
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            multiple={multiple}
            accept={acceptedTypes.join(',')}
            onChange={e => {
              const files = e.target.files;
              if (files) {
                // technically redundant, but better to be safe
                const filteredFiles = Array.from(files).filter(file =>
                  acceptedTypes.includes(file.type)
                );
                filteredFiles.length > 0 && onFileDrop(filteredFiles);
              }

              // NOTE: clear this inputs value so that re-triggering with the same file will work
              fileInputRef.current && (fileInputRef.current.value = '');
            }}
          />

          {children}
        </Box>
      </Box>
    );
  }
);

// NOTE: I don't like doubling up the forwardRef, but I really want this error boundary to be a built-in.
const Wrapper = forwardRef<FileDropzoneRef, FileDropzoneProps>((props, ref) => (
  <ErrorBoundary errorDisplay="Failed to load dropzone. Please refresh and try again.">
    <FileDropzone {...props} ref={ref} />
  </ErrorBoundary>
));

export default Wrapper;
