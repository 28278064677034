import type { BaseEditorImage, EditorProductImage } from '@odo/types/portal';
import { VideoPlatform } from '@odo/types/portal';

export const isImageElement = (el: Element): el is HTMLImageElement =>
  el.tagName === 'IMG';

export const isAnchorElement = (el: Element): el is HTMLAnchorElement =>
  el.tagName === 'ANCHOR';

export const getImageSrc = ({
  url,
  file,
  maxWidth,
}: {
  url: EditorProductImage['url'];
  file: EditorProductImage['file'];
  maxWidth?: number;
}) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  if (url) {
    return `${url}${maxWidth ? `?w=${maxWidth}&h=${maxWidth}` : ''}`;
  }
};

export const downloadImage = async (image: BaseEditorImage) => {
  let blob: Blob | null = null;
  if (image.url) {
    const response = await fetch(image.url);
    blob = await response.blob();
  } else if (image.file) {
    blob = image.file;
  }

  if (blob) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = image.file?.name || image.label || image.id || 'image';
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  }
};

const youtubeRegex =
  /^http?s:\/\/(www.)?(?:youtube|youtu)\.(?:be|com)\/(?:embed\/?|watch\?v=)?(.+)/i;
const vimeoRegex = /^http?s:\/\/(www.)?vimeo\.com\/(.+)/i;

const generateYoutubeIframe = (slug: string) =>
  `<iframe width="560" height="315" src="https://www.youtube.com/embed/${slug}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
const generateVimeoIframe = (slug: string) =>
  `<iframe width="640" height="360" src="https://player.vimeo.com/video/${slug}" title="Vimeo frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;

export const processVideoUrl = (url: string) => {
  let platform: VideoPlatform | undefined;
  let iframe: string | undefined;

  const youtubeMatch = url.match(youtubeRegex);
  const vimeoMatch = url.match(vimeoRegex);

  if (youtubeMatch && youtubeMatch[2]) {
    platform = VideoPlatform.youtube;
    iframe = generateYoutubeIframe(youtubeMatch[2]);
  } else if (vimeoMatch && vimeoMatch[2]) {
    platform = VideoPlatform.vimeo;
    iframe = generateVimeoIframe(vimeoMatch[2]);
  }

  return { platform, iframe };
};
