interface UserInfo {
  name: string;
  email: string;
  loginTime: string;
}
const isValidUserInfo = (userInfo: UserInfo | unknown): userInfo is UserInfo =>
  typeof (userInfo as UserInfo).name === 'string' &&
  typeof (userInfo as UserInfo).email === 'string' &&
  typeof (userInfo as UserInfo).loginTime === 'string';

export const getUserInfo = (): UserInfo | undefined => {
  try {
    const userInfo = window.localStorage.getItem('user');
    if (userInfo) {
      const user: unknown = JSON.parse(userInfo);
      if (isValidUserInfo(user)) {
        return {
          name: user.name,
          email: user.email,
          loginTime: user.loginTime,
        };
      }
    }
  } catch (e) {
    console.error(e);
  }
};
