import { getODOAdminUrl } from '@odo/utils/make-url';
import type { GridProduct } from './types';
import type { ApiProduct } from '@odo/types/api';
import { cacheGet } from '@odo/utils/cache';
import { IMAGE_CACHE_NAME } from './constants';
import { zarFormatter } from '@odo/utils/currency';

export const prepProductRow: (product: ApiProduct) => {
  rowId: string;
  data: GridProduct;
} = product => ({
  rowId: product.id,
  terms: [
    product.buyer,
    product.salesAssistant,
    product.brand,
    product.name,
    product.sku,
    product.dealType,
    product.campaign,
    ...(product.categories || []).map(cat => cat.categoryName),
    ...(product.price ? [zarFormatter(product.price)] : []),
    ...(product.cost ? [zarFormatter(product.cost)] : []),
  ],
  data: {
    id: product.id,
    status: product.status,
    image:
      product['thumbnail'] ||
      cacheGet({ cacheName: IMAGE_CACHE_NAME, cacheKey: product.id }),
    name: product.name,
    brand: product.brand,
    sku: product.sku,
    categories: (product.categories || []).map(cat => ({
      id: cat.categoryId,
      name: cat.categoryName,
    })),
    cost: product.cost,
    price: product.price,
    retail: product.retail,
    qty: product['inventory']['qty'] || null,
    activeFromDate: product.activeFromDate || null,
    activeToDate: product.activeToDate || null,
    buyer: product.buyer || 'N/A',
    salesAssistant: product.salesAssistant || 'N/A',
    priority: product.priority || null,
    preview: product.preview || null,
    priceForecastUrl: getODOAdminUrl({
      path: `/price_calculator?deal=${product.id}`,
    }),
    isSampleReceived: product.isSampleReceived,
    isPhotographedByStudio: product.isPhotographedByStudio,
    dealType: product.dealType,
    campaign: product.campaign,
    xtdDaysConfirmed: product.xtdDaysConfirmed,
    xtdDaysRequested: product.xtdDaysRequested,
  },
});
