import type {
  SpaceProps,
  BackgroundColorProps,
  LayoutProps,
  OpacityProps,
  BorderProps,
  BoxShadowProps,
  PositionProps,
} from '@odo/lib/styled';
import styled, {
  compose,
  space,
  color,
  layout,
  border,
  boxShadow,
  position,
} from '@odo/lib/styled';

export type BoxProps = SpaceProps &
  BackgroundColorProps &
  OpacityProps &
  LayoutProps &
  BorderProps &
  BoxShadowProps &
  PositionProps;

export const Box = styled.div<BoxProps>`
  ${compose(space, color, layout, border, boxShadow, position)}
`;
