import styled from '@odo/lib/styled';
import { useId } from 'react';
import { Flex, Box, type BoxProps } from '@odo/components/elements/layout';
import { Heading } from '@odo/components/elements/typography';
import { cssColor } from '@odo/utils/css-color';
import Button from '@odo/components/elements/button';
import { FaTimes as IconClose } from 'react-icons/fa';
import DialogPortal from '@odo/components/widgets/dialog/elements/portal';
import type { DialogType } from '@odo/components/widgets/dialog/elements/types';

type DialogOuterProps = BoxProps;

const DialogOuter = styled(Box)<DialogOuterProps>``;

DialogOuter.defaultProps = {
  bg: cssColor('foreground'),
  borderRadius: '1rem',
  boxShadow:
    '1px 2px 4px -2px hsl(240deg 33.33% 80% / 40%), 1px 2px 8px -2px hsl(240deg 33.33% 80% / 20%)',
};

export const BaseDialog = ({
  children,
  minWidth,
  minHeight,
  ...rest
}: DialogType & {
  minWidth?: string;
  minHeight?: string;
}) => (
  <DialogPortal {...rest}>
    <DialogOuter minWidth={minWidth} minHeight={minHeight}>
      {children}
    </DialogOuter>
  </DialogPortal>
);

const Dialog = ({
  children,
  title,
  close,
  ...restProps
}: DialogType & {
  title: string;
  minWidth?: string;
  minHeight?: string;
}) => {
  const labelId = useId();

  return (
    <BaseDialog labelledById={labelId} close={close} {...restProps}>
      <Flex
        backgroundColor={cssColor('dark-grey')}
        justifyContent="space-between"
        alignItems="center"
        borderTopLeftRadius="0.8rem"
        borderTopRightRadius="0.8rem"
        pl={3}
        pr={1}
        py={2}
        gap={[2, 3]}
      >
        <Heading id={labelId} color="white" fontSize={[3, 4]}>
          {title}
        </Heading>

        <Button
          hue="white"
          variant="flat"
          circular
          px={2}
          py={2}
          onClick={close}
        >
          <IconClose />
        </Button>
      </Flex>

      <Box p={3}>{children}</Box>
    </BaseDialog>
  );
};

export default Dialog;
