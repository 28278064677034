import React from 'react';
import { useSafeEffect } from '../../../hooks/useSafeEffect';
import { RPSCard, RPSCheckbox } from '@rps/web-components/build/react-wrappers';
import { DealConditionsAndCategory } from 'models/ConditionsAndCategory.jsx';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { Deal } from 'models/Deal.jsx';
import { usePreviousValue } from './../../../hooks/usePreviousValue';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { Input, Select } from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';

export const ConditionsSection = () => {
  const currentDeal = useCurrentDealSource();
  const model = currentDeal.deal.conditionsAndCategory;
  const handleInput = currentDeal.createInputHandler(
    Deal.MODELS.CONDITIONS_AND_CATEGORY
  );

  const warrantyPeriodOptions = useAttributeOptions(
    AttributeCode.warrantyPeriod
  );

  const applyAdditionalInfo = (condition, text, previous) => {
    if (condition) {
      if (model.additionalInfo?.match(new RegExp(text, 'ig'))) {
        return;
      }
      if (!model.additionalInfo?.match(new RegExp(text, 'ig'))) {
        if (model.additionalInfo?.length > 0) {
          const newText = `${model.additionalInfo} ${text}`;
          currentDeal.deal.set(
            Deal.MODELS.CONDITIONS_AND_CATEGORY,
            DealConditionsAndCategory.FIELDS.ADDITIONAL_INFO,
            newText
          );
        } else {
          const newText = `${text}`;
          currentDeal.deal.set(
            Deal.MODELS.CONDITIONS_AND_CATEGORY,
            DealConditionsAndCategory.FIELDS.ADDITIONAL_INFO,
            newText
          );
        }
        currentDeal.update();
      }
    } else {
      if (
        model.additionalInfo?.match(new RegExp(text, 'ig')) &&
        previous === true
      ) {
        const newText = model.additionalInfo
          .replace(new RegExp(text, 'gi'), '')
          .trim();
        currentDeal.deal.set(
          Deal.MODELS.CONDITIONS_AND_CATEGORY,
          DealConditionsAndCategory.FIELDS.ADDITIONAL_INFO,
          newText
        );
        currentDeal.update();
      }
    }
  };

  const previousIsParallelImport = usePreviousValue(model.isParallelImport);
  const parallelImportSlug = 'Parallel Import';
  useSafeEffect(() => {
    applyAdditionalInfo(
      model.isParallelImport,
      parallelImportSlug,
      previousIsParallelImport
    );
  }, [model.isParallelImport]);

  const previousIsAlcoholic = usePreviousValue(model.isAlcoholic);
  const alcoholWarningSlug = 'Not for persons under the age of 18';
  useSafeEffect(() => {
    applyAdditionalInfo(
      model.isAlcoholic,
      alcoholWarningSlug,
      previousIsAlcoholic
    );
  }, [model.isAlcoholic]);

  const handleAdditionalInfoInput = ev => {
    const newValue = ev.target.value;
    const oldValue = model.additionalInfo;

    ev.preventDefault();
    ev.stopPropagation();

    const parallelImportRegex = new RegExp(parallelImportSlug, 'ig');
    const isAlcoholicRegex = new RegExp(alcoholWarningSlug, 'ig');

    if (
      model.isParallelImport &&
      oldValue.match(parallelImportRegex) &&
      !newValue.match(parallelImportRegex)
    ) {
      currentDeal.deal.set(
        Deal.MODELS.CONDITIONS_AND_CATEGORY,
        DealConditionsAndCategory.FIELDS.ADDITIONAL_INFO,
        oldValue
      );
      currentDeal.update();
      return;
    }

    if (
      model.isAlcoholic &&
      oldValue.match(isAlcoholicRegex) &&
      !newValue.match(isAlcoholicRegex)
    ) {
      currentDeal.deal.set(
        Deal.MODELS.CONDITIONS_AND_CATEGORY,
        DealConditionsAndCategory.FIELDS.ADDITIONAL_INFO,
        oldValue
      );
      currentDeal.update();
      return;
    }

    currentDeal.deal.set(
      Deal.MODELS.CONDITIONS_AND_CATEGORY,
      DealConditionsAndCategory.FIELDS.ADDITIONAL_INFO,
      newValue
    );
    currentDeal.update();
  };

  // EFFECT: Default warrantyPeriod to first value in warranty list if it's not in the valid set.
  useSafeEffect(() => {
    if (
      warrantyPeriodOptions.length > 0 &&
      !warrantyPeriodOptions
        .map(item => item.value)
        .includes(model.warrantyPeriod)
    ) {
      const [firstWarrantyPeriod] = warrantyPeriodOptions;
      currentDeal.deal.conditionsAndCategory.warrantyPeriod =
        firstWarrantyPeriod.value;
      currentDeal.update();
    }
  }, [warrantyPeriodOptions, model.warrantyPeriod]);

  useSafeEffect(
    isMounted => {
      if (
        model.isParallelImport &&
        model.additionalInfo !== parallelImportSlug
      ) {
        if (isMounted) {
          currentDeal.deal.set(
            Deal.MODELS.CONDITIONS_AND_CATEGORY,
            DealConditionsAndCategory.FIELDS.ADDITIONAL_INFO,
            parallelImportSlug
          );
          currentDeal.update();
        }
      }
    },
    [model.additionalInfo, model.isParallelImport]
  );

  return (
    <RPSCard>
      <div slot="header">
        <h5>Conditions</h5>
      </div>
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            gap: '2rem',
            width: 'fit-content',
          }}
        >
          <RPSCheckbox
            label="Alcoholic"
            cbInput={handleInput(DealConditionsAndCategory.FIELDS.IS_ALCOHOLIC)}
            checked={model.isAlcoholic}
          />
          <RPSCheckbox
            label="Is Fragile?"
            cbInput={handleInput(DealConditionsAndCategory.FIELDS.IS_FRAGILE)}
            checked={model.isFragile}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            gap: '2rem',
            width: 'fit-content',
          }}
        >
          <RPSCheckbox
            label="Add Hygiene Disclaimer"
            cbInput={handleInput(DealConditionsAndCategory.FIELDS.IS_HYGIENIC)}
            checked={model.isHygienic}
          />
          <RPSCheckbox
            label="Parallel Import"
            cbInput={handleInput('isParallelImport')}
            checked={model.isParallelImport}
          />
        </div>
      </div>

      <Select
        label="Warranty"
        value={model.warrantyPeriod}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.CONDITIONS_AND_CATEGORY,
          field: DealConditionsAndCategory.FIELDS.WARRANTY_PERIOD,
        })}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...warrantyPeriodOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label.replace(/warrantyperiod/i, ''),
          })),
        ]}
        matchRPStyles
      />

      <Input
        label="Warranty Comments"
        value={model.warranty || ''}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.CONDITIONS_AND_CATEGORY,
          field: DealConditionsAndCategory.FIELDS.WARRANTY_COMMENTS,
        })}
        autoComplete="on"
        matchRPStyles
      />

      <Input
        label="Additional Info"
        value={model.additionalInfo || ''}
        onChange={handleAdditionalInfoInput}
        selectOnFocus={model.isParallelImport ? false : true}
        disabled={model.isParallelImport}
        maxLength={9999}
        autoComplete="on"
        matchRPStyles
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: '2rem',
          width: 'fit-content',
        }}
      >
        <RPSCheckbox
          label="Sample Received"
          cbInput={handleInput(
            DealConditionsAndCategory.FIELDS.IS_SAMPLE_RECEIVED
          )}
          checked={model.isSampleReceived}
        />

        <RPSCheckbox
          label="Supplier Accepts Returns?"
          checked={model.isReturnableToSupplier}
          cbInput={handleInput(
            DealConditionsAndCategory.FIELDS.SUPPLIER_ACCEPTS_RETURNS
          )}
        />
      </div>
    </RPSCard>
  );
};
