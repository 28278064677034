import {
  ButtonStrip,
  Checkbox,
  InputNumeric,
  InputNumericMasked,
  Select,
} from '@odo/components/elements/form-fields';
import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import type { AttributeOption } from '@odo/contexts/attributes';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { useAttributeOptions } from '@odo/hooks/attributes';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import CustomOptions from '@odo/screens/deal/editor/price-and-custom-options/custom-options';
import {
  OriginalStock,
  RebateDiscount,
} from '@odo/screens/deal/editor/widgets/shared-fields';
import { AttributeCode } from '@odo/types/api';
import type { EditorSurcharge } from '@odo/types/portal';
import { cssColor } from '@odo/utils/css-color';
import type { ReactNode } from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import {
  FaCalculator as IconCalculator,
  FaChevronDown as IconChevronDown,
} from 'react-icons/fa';
import { FiAlertCircle as IconAlert } from 'react-icons/fi';
import {
  SURCHARGE_INSURANCE_KEY,
  SURCHARGE_WEIGHT_KEY,
  POTENTIAL_PROFIT_TOOLTIP_COPY,
  WEIGHTED_MARGIN_TOOLTIP_COPY,
} from '@odo/screens/deal/editor/constants';
import Button from '@odo/components/elements/button';
import { calcSurchargeInsurance } from '@odo/helpers/calculations/general';
import { getAdminUrl, validateProduct } from '@odo/screens/deal/editor/helpers';
import {
  priceSectionValidators,
  surchargesSectionValidators,
} from '@odo/screens/deal/editor/price-and-custom-options/validators';
import Card from '@odo/components/elements/card';
import * as Accordion from '@radix-ui/react-accordion';
import styled from '@odo/lib/styled';
import { formatMoney } from '@odo/utils/currency';
import {
  useSavings,
  useProfitCalculation,
} from '@odo/screens/deal/editor/hooks';
import ButtonLink from '@odo/components/elements/button/link';
import Tooltip from '@odo/components/widgets/tooltip';

/**
 * NOTE: this accordion styling is quite specific to this screen for now.
 * TODO: if we find ourselves using accordions more, we should consider making a more generic component.
 */
const AccordionHeader = styled(Accordion.Header)`
  all: unset;
  display: flex;
`;

const AccordionArrow = styled(IconChevronDown)`
  transition: transform 350ms cubic-bezier(0.87, 0, 0.13, 1) 0s;
  color: ${cssColor('palette-blue')};
`;

const AccordionTrigger = styled(Accordion.Trigger)`
  all: unset;
  cursor: pointer;
  font-family: inherit;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
  color: ${cssColor('palette-blue')};
  border: 1px solid ${cssColor('border')};
  background-color: ${cssColor('foreground')};
  padding: 0px 12px;
  flex: 1 1 0%;
  border-radius: 6px;

  &:focus-visible {
    outline: ${cssColor('palette-blue')} auto 1px;
    outline-offset: 4px;
  }

  &[data-state='open'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & ${AccordionArrow} {
      transform: rotate(180deg);
    }
  }
`;

const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid ${cssColor('border')};
  padding: 8px;
  box-shadow: rgb(219, 216, 224) 0px 1px 1px;
  margin-top: -1px;

  &[data-state='closed'] {
    height: 0;
  }
  &[data-state='open'] {
    height: auto;
  }
`;

const NumberBadge = styled.span`
  padding: 2px;
  background: ${cssColor('palette-pink')};
  width: 20px;
  aspect-ratio: 1 / 1;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${cssColor('white')};
  font-weight: 800;
  margin-left: 12px;
`;

interface SavingsType {
  id: string;
  label: string;
}

type SurchargeValidation = '-' | '+';

const SurchargeInput = ({
  surcharge,
  surchargeType,
  cost,
}: {
  surcharge: EditorSurcharge;
  surchargeType?: AttributeOption;
  cost?: number | null;
}) => {
  const change = useChangeProduct();

  const validation: SurchargeValidation | undefined = useMemo(() => {
    if (!surchargeType) return;

    const meta =
      (surchargeType.metadata || []).find(({ key }) => key === 'validation')
        ?.value || '';

    if (meta === '-') return '-';
    if (meta === '+') return '+';
  }, [surchargeType]);

  return (
    <Grid gap={[2, 3]}>
      <GridItem gridColumn="1" gridRow="1">
        <InputNumericMasked
          label={surcharge.label}
          value={surcharge.value?.string || ''}
          type="currency"
          allowNegative={validation !== '+'}
          selectOnFocus
          disabled={surcharge.id === SURCHARGE_WEIGHT_KEY}
          onChange={nextSurcharge => {
            change({
              fieldId: `surcharges.${surcharge.id}`,
              label: `Surcharge (${surcharge.label})`,
              apply: to =>
                (to.surcharges = (to.surcharges || []).map(s =>
                  s.id === surcharge.id ? { ...s, value: nextSurcharge } : s
                )),
            });
          }}
          onBlur={() => {
            if (!validation || typeof surcharge.value.number !== 'number') {
              return;
            }

            const value = surcharge.value.number;
            const formatted =
              validation === '-' ? Math.abs(value) * -1 : Math.abs(value);

            if (value !== formatted) {
              change({
                fieldId: `surcharges.${surcharge.id}`,
                label: `Surcharge (${surcharge.label})`,
                apply: to =>
                  (to.surcharges = (to.surcharges || []).map(s =>
                    s.id === surcharge.id
                      ? {
                          ...s,
                          value: {
                            string: formatted.toString(),
                            number: formatted,
                          },
                        }
                      : s
                  )),
              });
            }
          }}
        />
      </GridItem>

      {/* NOTE: this grid overlay trick is working alright here... but it's less than ideal for repeated usage */}
      {/* TODO: implement a proper solution if we find ourselves needing buttons within inputs more */}
      {surcharge.id === SURCHARGE_INSURANCE_KEY && (
        <GridItem gridColumn="1" gridRow="1" style={{ pointerEvents: 'none' }}>
          <Flex
            height="100%"
            justifyContent="flex-end"
            alignItems="flex-end"
            pb={1}
            pr={1}
          >
            <Button
              hue="blue"
              variant="flat"
              circular
              px={1}
              py={1}
              disabled={typeof cost !== 'number'}
              style={{ pointerEvents: 'all' }}
              onClick={() => {
                if (typeof cost !== 'number') return;
                const insurance = calcSurchargeInsurance(cost);
                change({
                  fieldId: `surcharges.${surcharge.id}`,
                  label: `Surcharge (${surcharge.label})`,
                  apply: to =>
                    (to.surcharges = (to.surcharges || []).map(s =>
                      s.id === surcharge.id
                        ? {
                            ...s,
                            value: {
                              string: insurance.toString(),
                              number: insurance,
                            },
                          }
                        : s
                    )),
                });
              }}
            >
              <IconCalculator size="1.75rem" color={cssColor('palette-blue')} />
            </Button>
          </Flex>
        </GridItem>
      )}
    </Grid>
  );
};

const PriceSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(currentProduct, priceSectionValidators).status;

  const adminCostOptions = useAttributeOptions(AttributeCode.adminCost);
  const taxClassOptions = useAttributeOptions(AttributeCode.taxClass);

  const adminCostOptionsFiltered = useMemo(
    () =>
      adminCostOptions.filter(option => option.value.toLowerCase() !== 'none'),
    [adminCostOptions]
  );

  const taxClassOptionsFiltered = useMemo(
    () =>
      taxClassOptions.filter(
        a => !['PLEASE_SELECT', 'SHIPPING'].includes(a.key)
      ),
    [taxClassOptions]
  );

  const savings = useSavings();

  return (
    <SectionWrapper title="Pricing" status={status}>
      <Grid
        gap="24px"
        gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
      >
        <InputNumericMasked
          label="Cost"
          value={currentProduct.cost?.string || ''}
          type="currency"
          onChange={cost => {
            change({
              fieldId: 'cost',
              label: 'Cost',
              apply: to => (to.cost = cost),
            });
          }}
          required
          selectOnFocus
        />

        <InputNumericMasked
          label="Price"
          value={currentProduct.price?.string || ''}
          type="currency"
          onChange={price => {
            change({
              fieldId: 'price',
              label: 'Price',
              apply: to => (to.price = price),
            });
          }}
          required
          selectOnFocus
        />

        <InputNumericMasked
          label="Retail"
          value={currentProduct.retail?.string || ''}
          type="currency"
          onChange={retail => {
            change({
              fieldId: 'retail',
              label: 'Retail',
              apply: to => (to.retail = retail),
            });
          }}
          selectOnFocus
        />

        <OriginalStock />

        <RebateDiscount disabled />
      </Grid>

      <Select
        label="Admin Cost"
        value={currentProduct.adminCost?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const adminCost = adminCostOptions.find(
            adminCost => adminCost.value === value
          );
          change({
            fieldId: 'adminCost',
            label: 'Admin Cost',
            apply: to => {
              to.adminCost = adminCost
                ? {
                    id: adminCost.value,
                    label: adminCost.label,
                  }
                : undefined;
            },
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...adminCostOptionsFiltered.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
        required
      />

      <Tooltip
        placement="bottom-start"
        color={cssColor('palette-yellow')}
        showDelay={250}
        content={() =>
          'NONE can only be selected for VAT exempt products or International Travel.'
        }
      >
        <ButtonStrip
          label="Tax Class"
          hue="blue"
          selectedOption={{
            id: currentProduct.taxClass?.id || '',
            label: currentProduct.taxClass?.label || '',
          }}
          options={taxClassOptionsFiltered.map(option => ({
            id: option.value,
            label: option.label,
          }))}
          onSelect={option => {
            change({
              fieldId: 'taxClass',
              label: 'Tax Class',
              apply: to =>
                (to.taxClass = { id: option.id, label: option.label }),
            });
          }}
        />
      </Tooltip>

      {!!savings ? (
        <ButtonStrip<SavingsType>
          label="Customer Savings in Rands/Percentage"
          hue="blue"
          selectedOption={
            currentProduct.isSavingsInRands
              ? { id: 'rands', label: 'Rands' }
              : { id: 'percentage', label: 'Percentage' }
          }
          options={[
            {
              id: 'rands',
              label: `Save ${formatMoney(savings.fixed, { decimals: 0 })}`,
            },
            { id: 'percentage', label: `Save ${savings.percentage}%` },
          ]}
          onSelect={option => {
            change({
              fieldId: 'isSavingsInRands',
              label: 'Customer Savings in Rands/Percentage',
              apply: to => (to.isSavingsInRands = option.id === 'rands'),
            });
          }}
        />
      ) : (
        <Checkbox
          label="Display Savings In Rands?"
          checked={!!currentProduct.isSavingsInRands}
          onChange={e => {
            const checked = !!e.target.checked;
            change({
              fieldId: 'isSavingsInRands',
              label: 'Display Savings In Rands?',
              apply: to => (to.isSavingsInRands = checked),
            });
          }}
        />
      )}

      <Checkbox
        label="Show Retail Price on Deal?"
        checked={!!currentProduct.isDisplayRetail}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'isDisplayRetail',
            label: 'Show Retail Price on Deal?',
            apply: to => (to.isDisplayRetail = checked),
          });
        }}
      />
    </SectionWrapper>
  );
};

const SurchargesSection = () => {
  const currentProduct = useCurrentProduct();

  const status = validateProduct(
    currentProduct,
    surchargesSectionValidators
  ).status;

  const surchargeTypeOptions = useAttributeOptions(AttributeCode.surcharges);

  return (
    <SectionWrapper title="Surcharges" status={status}>
      <Grid gap="24px" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}>
        {(currentProduct.surcharges || []).map(surcharge => (
          <SurchargeInput
            key={surcharge.id}
            surcharge={surcharge}
            surchargeType={surchargeTypeOptions.find(
              option => option.key === surcharge.id
            )}
            cost={currentProduct.cost?.number}
          />
        ))}

        <GridItem
          gridColumn={[
            '1',
            // if we have an even number of surcharges, make the total span 2 columns from tablet up
            (currentProduct.surcharges || []).length % 2 === 0 ? '1/3' : '2',
          ]}
        >
          <InputNumeric
            label="Total"
            value={currentProduct.surcharge?.toString() || ''}
            prefix="R"
            disabled
          />
        </GridItem>
      </Grid>
    </SectionWrapper>
  );
};

const PricingCard = ({
  header,
  content,
  color,
  tooltip,
}: {
  header: ReactNode;
  content: ReactNode;
  color?: string;
  tooltip?: string;
}) => (
  <Card
    overflow="hidden"
    header={
      <Tooltip
        showDelay={250}
        placement="top"
        offset={16}
        disabled={!tooltip}
        content={() => tooltip}
      >
        <Flex gap={[1, 2]} color={cssColor('white')}>
          <Text textAlign="center" color={cssColor('white')}>
            {header}
          </Text>
          {!!tooltip && <IconAlert size={16} />}
        </Flex>
      </Tooltip>
    }
    headerProps={{
      justifyContent: 'center',
      backgroundColor: cssColor('palette-blue'),
      ...(color && { backgroundColor: color }),
    }}
  >
    <Flex justifyContent="center">
      <Heading fontWeight={800} fontSize={[2, 3]}>
        {content}
      </Heading>
    </Flex>
  </Card>
);

const CalculatorSection = () => {
  const currentProduct = useCurrentProduct();
  const profit = useProfitCalculation();

  const weightedMargins = useMemo(
    () =>
      (profit?.margins || []).filter(
        m => typeof m.weight !== 'undefined' && m.weight > 0
      ),
    [profit?.margins]
  );

  const countNegativeMargins = useMemo(
    () => weightedMargins.filter(m => m.profitMargin.raw < 0).length,
    [weightedMargins]
  );

  return (
    <SectionWrapper
      title="Price Calculator"
      headerExtras={
        !!currentProduct.id && (
          <ButtonLink
            hue="blue"
            variant="solid"
            target="_blank"
            href={getAdminUrl({
              dest: 'priceCalculator',
              productId: currentProduct.id,
            })}
          >
            ODO Admin Price Calculator
          </ButtonLink>
        )
      }
    >
      {!profit && (
        <Text>
          Profit potential will be calculated after filling in cost, price, and
          original stock.
        </Text>
      )}

      {!!profit && (
        <>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gap={[2, 3]}>
            {profit.profitPotential && (
              <PricingCard
                header="Profit Potential"
                tooltip={POTENTIAL_PROFIT_TOOLTIP_COPY}
                content={formatMoney(profit.profitPotential)}
                color={
                  profit.profitPotential < 0
                    ? cssColor('palette-pink')
                    : undefined
                }
              />
            )}

            {profit.weightedAverage && (
              <PricingCard
                header="Weighted Margin"
                tooltip={WEIGHTED_MARGIN_TOOLTIP_COPY}
                content={`${formatMoney(profit.weightedAverage.rands)} / ${
                  profit.weightedAverage.percentage
                }%`}
                color={
                  profit.weightedAverage.raw < 0
                    ? cssColor('palette-pink')
                    : undefined
                }
              />
            )}
          </Grid>

          <Accordion.Root type="single" collapsible>
            <Accordion.Item value="margins">
              <AccordionHeader>
                <AccordionTrigger>
                  <span>
                    Margins
                    {countNegativeMargins > 0 && (
                      <NumberBadge>{countNegativeMargins}</NumberBadge>
                    )}
                  </span>
                  <AccordionArrow />
                </AccordionTrigger>
              </AccordionHeader>

              <AccordionContent>
                <Grid gap={0} gridTemplateColumns="1fr auto auto">
                  {weightedMargins.map((margin, idx) => {
                    const color =
                      margin.profitMargin.raw < 0
                        ? cssColor('palette-pink')
                        : undefined;

                    const borderProps =
                      idx > 0
                        ? {
                            style: {
                              borderTop: `1px solid ${cssColor('border')}`,
                            },
                          }
                        : {};

                    return (
                      <Fragment key={margin.code}>
                        <Text py="6px" pl="6px" pr="12px" {...borderProps}>
                          {margin.label}:
                        </Text>

                        <Heading
                          py="6px"
                          px="12px"
                          fontSize={1}
                          fontWeight={800}
                          textAlign="right"
                          color={color}
                          {...borderProps}
                        >
                          {formatMoney(margin.profitMargin.rands)}
                        </Heading>

                        <Heading
                          py="6px"
                          pl="12px"
                          pr="6px"
                          fontSize={1}
                          fontWeight={800}
                          textAlign="right"
                          color={color}
                          {...borderProps}
                        >
                          {margin.profitMargin.percentage.toFixed(2)}%
                        </Heading>
                      </Fragment>
                    );
                  })}
                </Grid>
              </AccordionContent>
            </Accordion.Item>
          </Accordion.Root>
        </>
      )}
    </SectionWrapper>
  );
};

const CustomOptionsSection = () => {
  const change = useChangeProduct();

  const setCumulativeQuantity = useCallback(
    (qty: number) => {
      change({
        fieldId: 'customOptions.cumulativeQuantity',
        label: 'Cumulative Custom Options Quantity',
        apply: to =>
          (to.inventory = to.inventory
            ? { ...to.inventory, qty: { string: qty.toString(), number: qty } }
            : undefined),
      });
    },
    [change]
  );

  return (
    <SectionWrapper title="Custom Options">
      <CustomOptions setCumulativeQuantity={setCumulativeQuantity} />
    </SectionWrapper>
  );
};

const PriceAndCustomOptionsScreen = () => (
  <ErrorBoundary>
    <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gap={[3, 4]}>
      <PriceSection />

      <Grid gap={[3, 4]} alignContent="flex-start">
        <SurchargesSection />
        <CalculatorSection />
      </Grid>

      <GridItem gridColumn={['1/2', '1/3']}>
        <CustomOptionsSection />
      </GridItem>
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default PriceAndCustomOptionsScreen;
