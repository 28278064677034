import type { CategoryOrBreadcrumb, EditorCategory } from '@odo/types/portal';
import type {
  ApiCategoryBreadcrumb,
  ApiCategoryOrBreadcrumb,
  CategoryWithBreadcrumb,
} from './api';
import type { BaseProductImage } from './api-new';

export const isCategory = (
  option: ApiCategoryOrBreadcrumb
): option is CategoryWithBreadcrumb =>
  typeof (option as CategoryWithBreadcrumb).categoryId === 'string';

export const isCategoryBreadcrumb = (
  option: ApiCategoryOrBreadcrumb
): option is ApiCategoryBreadcrumb =>
  typeof (option as ApiCategoryBreadcrumb).breadcrumb === 'string';

export const isHTMLElement = (node: Element | Node): node is HTMLElement =>
  node.nodeType === Node.ELEMENT_NODE;

// NOTE: although this field is optional from the API, we don't want to consider it valid without an ID
type ValidProductImage = BaseProductImage & { id: string };

export const isValidImage = (
  image: BaseProductImage | unknown
): image is ValidProductImage =>
  typeof (image as BaseProductImage).id === 'string';

export const isEditorCategory = (
  category: CategoryOrBreadcrumb
): category is EditorCategory =>
  typeof (category as EditorCategory).categoryName === 'string';

export const isEditorCategoryBreadcrumb = (
  category: CategoryOrBreadcrumb
): category is ApiCategoryBreadcrumb =>
  typeof (category as ApiCategoryBreadcrumb).breadcrumb === 'string';

export const isNumber = (value: unknown): value is number =>
  typeof value === 'number' && !isNaN(value);
