// NOTE: we're assuming that standards have been met and there will be a max of 1 pipe character
export const incrementSku = (sku: string) => {
  const [base, suffix] = sku.split('|');
  return `${base}|${
    suffix && !isNaN(parseInt(suffix, 10)) ? parseInt(suffix, 10) + 1 : 1
  }`;
};

export const getSkuBrandPrefix = (sku: string) => {
  const [brandPrefix] = sku.split('-');
  return `${brandPrefix}-`;
};
