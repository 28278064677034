import { ValidationModel } from './base/ValidationModel.jsx';
import { isPositiveNumeric } from '@odo/utils/validation.ts';

const ShippingProperties = {
  isShippingApplied: true,
  length: '',
  width: '',
  height: '',
  weight: 0.0,
  isShippedIndividually: false,
  shippingCost: '',
  isDeliveredBySupplier: false,
  supplierRepacks: null,
  manage: false,
  qty: 0,
  cumulativeCustomOptionsQty: undefined,
  minSaleQuantity: 0,
  maximumSaleQuantity: 10,
  useConfigMinSaleQty: true,
  useConfigMaxSaleQty: true,
  qtyOutOfStockThreshold: 0,
  qtyUsesDecimals: false,
  backorders: [],
  notifyForQuantity: '',
  qtyIncrements: false,
  isInStock: false,
  originalStock: 0,
  customerDeliveryTime: null,
  isApplyMaxSaleQtyToProductOptions: false,
  isApplyMaxSaleQtyCustomerProfile: false,
};
const ShippingValidators = {
  partial: {
    shippingCost: {
      validator: model => model.shippingCost >= 0,
      error: 'Manual Shipping cost must be greater than 0',
    },
    dimensions: {
      validator: model =>
        isPositiveNumeric(model.width) &&
        isPositiveNumeric(model.height) &&
        isPositiveNumeric(model.length),
      error: 'Shipping width, height and length are required and must be >= 0.',
    },
    weight: {
      validator: model => isPositiveNumeric(model.weight),
      error: 'Shipping weight is required and must be >= 0.',
    },
    supplierRepacks: {
      validator: model =>
        model.supplierRepacks !== 'NONE' && model.supplierRepacks,
      error: 'Supplier repacks is required',
    },
    customerDeliveryTime: {
      validator: x => x.customerDeliveryTime,
      error: 'Customer delivery time is required',
    },
  },
  complete: {
    originalStock: {
      validator: model => model.originalStock > 0,
      error: 'Original stock should be greater than 0.',
    },
    /**
     * Warn the buyer if the quantity here isn't the same as the cumulative custom options quantity.
     */
    qty: {
      validator: model =>
        typeof model.cumulativeCustomOptionsQty !== 'number' ||
        parseInt(model.qty) === parseInt(model.cumulativeCustomOptionsQty),
      error:
        'Quantity Available does not match the Custom Options Quantity Total',
    },
  },
};

/**
 * @export
 * @class DealShipping
 */
export class DealShipping extends ValidationModel {
  constructor() {
    super(ShippingProperties, ShippingValidators);

    /** @type {Boolean} */
    this.isShippingApplied;
    this.length;
    this.width;
    this.height;
    this.weight;
    this.isShippedIndividually;
    this.shippingCost;
    this.isDeliveredBySupplier;
    this.supplierRepacks;
    this.originalStock;
    this.isInStock;
    this.qty;
    this.cumulativeCustomOptionsQty;
    this.minSaleQuantity;
    this.maximumSaleQuantity;
    this.useConfigMinSaleQty;
    this.useConfigMaxSaleQty;
    this.customerDeliveryTime;
    this.isApplyMaxSaleQtyToProductOptions;
    this.isApplyMaxSaleQtyCustomerProfile;
  }
}

DealShipping.FIELDS = {
  APPLY_SHIPPING: 'isShippingApplied',
  LENGTH: 'length',
  WIDTH: 'width',
  HEIGHT: 'height',
  WEIGHT: 'weight',
  INDIVIDUAL_SHIPPING: 'isShippedIndividually',
  SHIPPING_COST: 'shippingCost',
  SUPPLIER_DELIVERS: 'isDeliveredBySupplier',
  SUPPLIER_REPACKS: 'supplierRepacks',
  AVAILABLE: 'isInStock',
  ORIGINAL_STOCK: 'originalStock',
  X_QUANTITY_LEFT: 'qty',
  CUMULATIVE_CUSTOM_OPTIONS_QTY: 'cumulativeCustomOptionsQty',
  USE_CONFIG_MIN_QTY_CART: 'useConfigMinSaleQty',
  USE_CONFIG_MAX_QTY_CART: 'useConfigMaxSaleQty',
  MIN_QTY_IN_SHOPPING_CART: 'minSaleQuantity',
  MAX_QTY_IN_SHOPPING_CART: 'maximumSaleQuantity',
  CUSTOMER_DELIVERY_TIME: 'customerDeliveryTime',
  IS_APPLY_MAX_SALE_QTY_TO_PRODUCT_OPTIONS: 'isApplyMaxSaleQtyToProductOptions',
  IS_APPLY_MAX_SALE_QTY_TO_CUSTOMER_PROFILE: 'isApplyMaxSaleQtyCustomerProfile',
};
