import type { HTMLAttributes } from 'react';
import { useState, useEffect, useRef } from 'react';
import type { LayoutProps } from '@odo/lib/styled';
import styled, { compose, layout } from '@odo/lib/styled';
import { isHTMLElement } from '@odo/types/guards';
import type { DialogType } from './types';
import { focusable, tabbable } from '@odo/utils/tabbable';

type DialogContentWrapperProps = LayoutProps;

const DialogContentWrapper = styled.div<DialogContentWrapperProps>`
  max-width: calc(100vw - 20px);
  /* NOTE: we have two max-height declarations */
  /* the latter uses dynamic viewport height which is better for mobile devices but isn't fully supported */
  /* so the former will be used as a fallback when the latter fails */
  max-height: calc(90vh - 20px);
  max-height: calc(100dvh - 20px);

  /* our enter/exit animations */
  transition: transform 100ms ease, opacity 75ms ease;
  @media (prefers-reduced-motion) {
    transition: unset;
  }

  opacity: 0;
  transform: translateY(50px);

  &.active {
    opacity: 1;
    transform: translateY(0px);
  }

  ${compose(layout)}
`;

DialogContentWrapper.defaultProps = {
  overflow: 'auto',
};

const HIDDEN_STYLES: React.CSSProperties = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'fixed',
  whiteSpace: 'nowrap',
  width: '1px',
  top: 0,
  left: 0,
};

let rafId = 0;
const enqueueFocus = (el: HTMLElement) => {
  window.cancelAnimationFrame(rafId);
  rafId = window.requestAnimationFrame(() => el.focus());
};

const FocusGuard = (props: HTMLAttributes<HTMLSpanElement>) => {
  const [role, setRole] = useState<'button' | undefined>();

  useEffect(() => {
    // NOTE: not sure about this user agent check, the original was an apple vendor check
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      setRole('button');
    }
  }, []);

  return (
    <span
      tabIndex={0}
      role={role}
      aria-hidden={role ? undefined : true}
      style={HIDDEN_STYLES}
      {...props}
    />
  );
};

const DialogInner = ({
  children,
  labelledById,
  describedById,
  autoFocus,
  isOpen,
  close,
}: DialogType) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const lastActiveElement = useRef<HTMLElement | undefined>();
  const [internalOpen, setInternalOpen] = useState(false);

  /**
   * Focus first focusable element on open.
   * NOTE: uses internal open as it must wait for the content to be rendered.
   */
  useEffect(() => {
    if (!autoFocus || !internalOpen || !contentRef.current) return;
    const [first] = focusable(contentRef.current);
    if (first) enqueueFocus(first);
  }, [internalOpen, autoFocus]);

  /**
   * Return focus to previously focused element on close.
   * NOTE: uses internal open as it must wait for the content to be removed.
   */
  useEffect(() => {
    if (internalOpen) return;

    if (lastActiveElement.current) {
      enqueueFocus(lastActiveElement.current);
      lastActiveElement.current = undefined;
    }
  }, [internalOpen]);

  /**
   * Show/hide state toggle and exit animation.
   */
  useEffect(() => {
    if (internalOpen === isOpen) return;

    if (isOpen) {
      // capture currently focused element
      if (document.activeElement && isHTMLElement(document.activeElement)) {
        lastActiveElement.current = document.activeElement;
      }

      // render content
      setInternalOpen(true);
    } else {
      // exit animation
      if (wrapperRef.current) {
        const wrapper = wrapperRef.current;
        wrapper.classList.remove('active');
      }

      // wait for transition to finish, then remove
      const timeoutId = setTimeout(() => setInternalOpen(false), 100);

      return () => clearTimeout(timeoutId);
    }
  }, [internalOpen, isOpen]);

  /**
   * Enter animation.
   * NOTE: We want to wait for the DOM node to be rendered before trying to animate it.
   */
  useEffect(() => {
    if (!internalOpen || !wrapperRef.current) return;
    const wrapper = wrapperRef.current;
    wrapper.classList.add('active');
  }, [internalOpen]);

  /**
   * Close on escape keyup.
   *
   * NOTE: keypress event is deprecated.
   * but still technically works for everything but the escape key.
   */
  useEffect(() => {
    if (!isOpen) return;

    const escapeClose = (e: KeyboardEvent) => e.key === 'Escape' && close();

    document.addEventListener('keyup', escapeClose);
    return () => document.removeEventListener('keyup', escapeClose);
  }, [isOpen, close]);

  return (
    <>
      {internalOpen && (
        <DialogContentWrapper
          ref={wrapperRef}
          role="dialog"
          tabIndex={-1}
          aria-labelledby={labelledById}
          aria-describedby={describedById}
          aria-modal="true"
        >
          {/* focus guard: wraps shift-tab onto first element back down to the last */}
          <FocusGuard
            onFocus={() => {
              if (!contentRef.current) return;
              const [last] = [...tabbable(contentRef.current)].reverse();
              if (last) enqueueFocus(last);
            }}
          />

          {/* render the main content of our dialog */}
          <div ref={contentRef}>{children}</div>

          {/* focus guard: wraps tab onto last element back up to the first */}
          <FocusGuard
            onFocus={() => {
              if (!contentRef.current) return;
              const [first] = tabbable(contentRef.current);
              if (first) enqueueFocus(first);
            }}
          />
        </DialogContentWrapper>
      )}
    </>
  );
};

export default DialogInner;
