import type {
  AlignContentProps,
  AlignItemsProps,
  AlignSelfProps,
  GapProps,
  GridAreaProps,
  GridAutoColumnsProps,
  GridAutoFlowProps,
  GridAutoRowsProps,
  GridColumnProps,
  GridRowProps,
  GridTemplateAreasProps,
  GridTemplateColumnsProps,
  GridTemplateRowsProps,
  JustifyContentProps,
  JustifyItemsProps,
  JustifySelfProps,
  OrderProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
  BorderProps,
  PlaceContentProps,
  PlaceItemsProps,
} from '@odo/lib/styled';
import styled, {
  compose,
  alignContent,
  alignItems,
  alignSelf,
  gap,
  gridArea,
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumn,
  gridRow,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  justifyContent,
  justifyItems,
  justifySelf,
  order,
  space,
  color,
  layout,
  border,
  placeContent,
  placeItems,
} from '@odo/lib/styled';

export type GridBoxProps = GapProps &
  GridAutoFlowProps &
  GridAutoColumnsProps &
  GridAutoRowsProps &
  GridTemplateAreasProps &
  GridTemplateColumnsProps &
  GridTemplateRowsProps &
  JustifyContentProps &
  JustifyItemsProps &
  AlignContentProps &
  AlignItemsProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  BorderProps &
  PlaceContentProps &
  PlaceItemsProps;

export const Grid = styled.div<GridBoxProps>`
  display: grid;
  ${compose(
    gap,
    gridAutoFlow,
    gridAutoColumns,
    gridAutoRows,
    gridTemplateAreas,
    gridTemplateColumns,
    gridTemplateRows,
    justifyContent,
    justifyItems,
    alignContent,
    alignItems,
    space,
    color,
    layout,
    border,
    placeContent,
    placeItems
  )}
`;

export type GridItemProps = GridAreaProps &
  GridColumnProps &
  GridRowProps &
  JustifySelfProps &
  AlignSelfProps &
  OrderProps &
  LayoutProps &
  SpaceProps;

export const GridItem = styled.div<GridItemProps>`
  ${compose(
    gridArea,
    gridColumn,
    gridRow,
    justifySelf,
    alignSelf,
    order,
    space,
    layout
  )}
`;
