import React from 'react';
import {
  RPSListContainer,
  RPSCard,
} from '@rps/web-components/build/react-wrappers';
import { Deal } from './../../models/Deal.jsx';
import { InventorySection } from './shipping/InventorySection';
import { ShippingSection } from './shipping/ShippingSection';
import { PackageSection } from './shipping/PackageSection';
import { FormLayout } from './shared/FormLayout.jsx';
import { Grid } from '@odo/components/elements/layout/grid';

export const ShippingScreen = () => {
  return (
    <FormLayout model={Deal.MODELS.SHIPPING}>
      <Grid gap={[2, 3]} gridTemplateColumns={['1fr', null, '1fr 2fr']}>
        <InventorySection />
        <RPSCard css=":host { width: fit-content%; }">
          <RPSListContainer className="horizontal">
            <ShippingSection />
            <PackageSection />
          </RPSListContainer>
        </RPSCard>
      </Grid>
    </FormLayout>
  );
};
