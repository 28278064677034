import { cssColor } from '@odo/utils/css-color';
import { Box } from '@odo/components/elements/layout';
import { Heading } from '@odo/components/elements/typography';

/**
 * TODO: we can remove this badge and all usages when we remove the old deal editor.
 */
const V2Badge = () => (
  <Box px={2} py={1} my={-2} bg={cssColor('palette-blue')} borderRadius="32px">
    <Heading
      fontSize="1rem"
      lineHeight="1"
      letterSpacing="0.05em"
      fontWeight={800}
      color={cssColor('white')}
    >
      V2
    </Heading>
  </Box>
);

export default V2Badge;
