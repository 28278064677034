import { useDealId } from '@odo/screens/deal/editor/hooks';
import type { ReactNode } from 'react';
import { Fragment } from 'react';
import { ProductProvider as OldProductProvider } from '@odo/contexts/product';
import { ProductProvider } from '@odo/contexts/product-new';
import { CustomOptionsEditorProvider } from '@odo/contexts/custom-options-editor';

/**
 * NOTE: this wrapper puts the dealId url param as a key on the fragment wrapping product data specific providers.
 * This results in each provider being re-mounted whenever the dealId changes.
 * Hopefully mitigating a lot of issues with conflicting or stale data.
 *
 * TODO: OldProductProvider & CustomOptionsEditorProvider can both eventually be removed
 * OldProductProvider when we remove the RP deal editor (and can update the custom options to use the new provider)
 * CustomOptionsEditorProvider when we integrate custom options into the product mutation (BP-664)
 */
const Providers = ({ children }: { children: ReactNode }) => {
  const { id } = useDealId();

  return (
    <Fragment key={id}>
      <OldProductProvider>
        <ProductProvider>
          <CustomOptionsEditorProvider newDealEditor>
            {children}
          </CustomOptionsEditorProvider>
        </ProductProvider>
      </OldProductProvider>
    </Fragment>
  );
};

export default Providers;
