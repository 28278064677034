import { cssColor } from '@odo/utils/css-color';
import { Flex } from '@odo/components/elements/layout/flex';
import Button from '@odo/components/elements/button';
import Tooltip from '@odo/components/widgets/tooltip';
import Dialog from '@odo/components/widgets/dialog';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text } from '@odo/components/elements/typography';
import { FaSave as IconSave } from 'react-icons/fa';
import type { ProductChange } from '@odo/contexts/product-editor';
import {
  useCurrentProduct,
  useProductEditor,
} from '@odo/contexts/product-editor';
import type { Validity } from '@odo/screens/deal/editor/helpers';
import { Status } from '@odo/screens/deal/editor/types';
import updateProduct from '@odo/data/product/update';
import createProduct from '@odo/data/product/create';
import { error, success, invertedSuccessColors } from '@odo/utils/toast';
import { useSetProductAfterSave } from '@odo/contexts/product-new';
import type { GetProductInterface } from '@odo/types/api-new';
import { getProductToEditorProduct } from '@odo/transformers/product';
import { useAttributeContext } from '@odo/hooks/attributes';
import type { CustomOptionsEditorContextType } from '@odo/contexts/custom-options-editor';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import type { EditorProductInterface } from '@odo/types/portal';
import type { Attribute } from '@odo/contexts/attributes';
import { loadBreadcrumbs } from '@odo/data/product/category';
import { useNavigate } from 'react-router-dom';
import { useDealId, useHasChanges } from '@odo/screens/deal/editor/hooks';
import { deleteDraft } from '@odo/data/product/draft/cache';

const LOADING_TITLE = 'Uploading';

const createOrUpdate = async ({
  id,
  product,
  changes,
  attributes,
}: {
  id?: string;
  product: EditorProductInterface;
  changes: ProductChange[];
  attributes: Attribute[];
}): Promise<GetProductInterface> => {
  if (id && product.isNew) {
    throw new Error(
      'Aborting. Cannot tell if product should be created or updated.'
    );
  }

  if (id) {
    return await updateProduct({ id, changes, attributes });
  } else if (product.isNew) {
    return await createProduct({ product });
  }

  throw new Error('Failed to save product.');
};

const saveProduct = async ({
  id,
  product,
  changes,
  attributes,
  mustSaveCustomOptions,
  saveCustomOptions,
  onComplete,
  onError,
}: {
  id?: string;
  product: EditorProductInterface;
  changes: ProductChange[];
  attributes: Attribute[];
  mustSaveCustomOptions: boolean;
  saveCustomOptions: CustomOptionsEditorContextType['saveActions'];
  onComplete: (product: GetProductInterface) => void;
  onError: (error: string) => void;
}) => {
  try {
    // create or update product
    const saveResult = await createOrUpdate({
      id,
      product,
      changes,
      attributes,
    });

    success('Deal saved!', {
      ...invertedSuccessColors,
      position: 'top-center',
      duration: 7500,
    });

    // extract properties for custom options save
    const productId = saveResult.id;
    const stockId = saveResult.inventory?.id;
    const latestQty = saveResult.inventory?.qty;

    // trigger and wait for custom options save if needed
    if (mustSaveCustomOptions && productId && stockId) {
      // NOTE: the custom options save function has it's own toasts
      await saveCustomOptions({ productId, stockId, latestQty });
    }

    onComplete(saveResult);
  } catch (e) {
    onError(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : typeof e === 'string'
        ? e
        : 'Failed to save product.'
    );
  }
};

const prepareEditorProduct = async ({
  product,
  attributes,
  onComplete,
  onError,
}: {
  product: GetProductInterface;
  attributes: Attribute[];
  onComplete: (product: EditorProductInterface) => void;
  onError: (message: string) => void;
}) => {
  try {
    const breadcrumbs = await loadBreadcrumbs({
      categories: product.categories,
    });

    const editorProduct = getProductToEditorProduct({
      product,
      attributes,
      breadcrumbs,
    });
    if (!editorProduct) {
      throw new Error(
        'Failed to transform updated product data for editing. Reload the page to see your changes.'
      );
    }

    onComplete(editorProduct);
  } catch (e) {
    onError(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'Changes have saved, but we failed to show the updated product. Please reload the page.'
    );
  }
};

const SaveButton = ({ status }: { status?: Validity['status'] }) => {
  const { id, isDraft } = useDealId();
  const navigate = useNavigate();

  const { getChanges, setLock } = useProductEditor();
  const currentProduct = useCurrentProduct();
  const setProductAfterSave = useSetProductAfterSave();
  const hasChanges = useHasChanges();

  const titleRef = useRef<
    { title: string; intervalId?: NodeJS.Timeout } | undefined
  >();

  const [confirmSave, setConfirmSave] = useState(false);
  const [saving, setSaving] = useState(false);

  const { attributes } = useAttributeContext();

  const {
    canSave: mustSaveCustomOptions,
    validate: validateCustomOptions,
    saveActions: saveCustomOptions,
  } = useCustomOptionsEditorContext();

  const canSave = useMemo(
    () => hasChanges && status !== 'error',
    [hasChanges, status]
  );

  /**
   * Set the page title while uploading/saving.
   */
  const setTitle = useCallback(() => {
    // get our original title
    const originalTitle = document.title;

    // set our loading title
    document.title = LOADING_TITLE;

    // set a ref with the original title and an interval for the ellipsis on our loading title
    titleRef.current = {
      title: originalTitle,
      intervalId: setInterval(() => {
        if (document.title === `${LOADING_TITLE}...`) {
          // reset at 3 dots
          document.title = LOADING_TITLE;
        } else {
          // add another dot
          document.title = `${document.title}.`;
        }
      }, 750),
    };
  }, []);

  /**
   * Reset the page title after saving.
   */
  const resetTitle = useCallback((statusMessage: string) => {
    if (titleRef.current) {
      // remove the loading interval
      clearInterval(titleRef.current?.intervalId);
      // set our status message
      document.title = statusMessage;
      // reset back to original title after a few seconds, and cleanup ref
      setTimeout(() => {
        if (titleRef.current) {
          document.title = titleRef.current.title;
          titleRef.current = undefined;
        }
      }, 4000);
    }
  }, []);

  /**
   * Save success will reset product data and cleanup state.
   */
  const onSaveSuccess = useCallback(
    async (editorProduct: EditorProductInterface) => {
      if (isDraft) {
        if (id) await deleteDraft(id);

        navigate(`/new/deals/editor/${editorProduct.id}/buyer-and-supplier`);
      } else {
        setProductAfterSave(editorProduct);
      }

      // reset/cleanup
      setSaving(false);
      setConfirmSave(false);
      setLock(false);
      resetTitle('Deal Saved :)');
    },
    [setProductAfterSave, setLock, resetTitle, id, isDraft, navigate]
  );

  /**
   * Save error will show a message and cleanup state.
   */
  const onSaveError = useCallback(
    (message?: string) => {
      !!message && error(message);

      // reset/cleanup
      setSaving(false);
      setConfirmSave(false);
      setLock(false);
      resetTitle('Failed!!!');
    },
    [setLock, resetTitle]
  );

  /**
   * Some preparations before saving.
   *
   * Validation, loading, and locks.
   */
  const preSave = useCallback(() => {
    // make absolutely certain that it's safe to save (redundancy)
    if (!canSave) {
      error('Must fix errors before you can save.');
      return false;
    }

    // TODO: consider moving our SKU validation here. although the update vs create logic might be a bit different.

    // check custom options validity
    if (mustSaveCustomOptions && !validateCustomOptions()) {
      error(
        'Custom Options have errors that need to be resolved before saving.'
      );
      return false;
    }

    // start loading and lock changes
    setSaving(true);
    setTitle();
    setLock(true, { reason: 'Saving deal', withNotice: true });

    return true;
  }, [
    canSave,
    mustSaveCustomOptions,
    validateCustomOptions,
    setLock,
    setTitle,
  ]);

  /**
   * After save is complete we need to prepare the latest data for editing.
   */
  const postSave = useCallback(
    (product: GetProductInterface) => {
      prepareEditorProduct({
        product,
        attributes,
        onComplete: onSaveSuccess,
        onError: onSaveError,
      });
    },
    [attributes, onSaveSuccess, onSaveError]
  );

  /**
   * Triggering of the actual save.
   */
  const save = useCallback(() => {
    // pre-save itself will handle any notifications
    if (!preSave()) return;

    saveProduct({
      // main requirements for saving
      id: currentProduct.id,
      product: currentProduct,
      changes: getChanges(),
      attributes,
      // extra custom options stuff (for now)
      mustSaveCustomOptions,
      saveCustomOptions,
      // callbacks
      onComplete: postSave,
      onError: onSaveError,
    });
  }, [
    currentProduct,
    getChanges,
    attributes,
    mustSaveCustomOptions,
    saveCustomOptions,
    onSaveError,
    preSave,
    postSave,
  ]);

  /**
   * Cleanup the document title if this component unmounts before it's fully reset.
   */
  useEffect(() => {
    return () => {
      if (titleRef.current) {
        clearInterval(titleRef.current?.intervalId);
        document.title = titleRef.current.title;
      }
    };
  }, []);

  return (
    <>
      {/* save button + tooltip */}
      <Tooltip
        showDelay={500}
        hideDelay={500}
        content={() => (
          <>
            {status === Status.error
              ? 'Must fix errors before you can save.'
              : 'You have some warnings but can still save.'}
          </>
        )}
        disabled={!status || status === Status.valid}
        color={
          status === Status.error
            ? cssColor('palette-pink')
            : cssColor('palette-yellow')
        }
      >
        <Button
          hue="blue"
          variant={canSave ? 'solid' : 'outlined'}
          disabled={!canSave || confirmSave || saving}
          loading={saving}
          onClick={() => setConfirmSave(true)}
        >
          <IconSave />
          Save
        </Button>
      </Tooltip>

      {/* save confirmation dialog */}
      <Dialog
        title="Save Product"
        isOpen={confirmSave}
        close={() => setConfirmSave(false)}
      >
        <Flex flexDirection="column" gap={3}>
          <Text>Are you sure you wanna save this product?</Text>

          <Flex justifyContent="space-between" gap={3}>
            <Button
              hue="grey"
              variant="flat"
              onClick={() => setConfirmSave(false)}
            >
              Cancel
            </Button>
            <Button hue="blue" variant="solid" onClick={save} loading={saving}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </>
  );
};

export default SaveButton;
