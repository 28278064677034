import { SearchableDropdown } from '@odo/components/elements/form-fields';
import { Flex } from '@odo/components/elements/layout/flex';
import { AttributeCode } from '@odo/types/api';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { useCallback, useMemo, useState } from 'react';
import type { SupplierOptionType } from '@odo/helpers/attributes';
import {
  attributeToSupplierOption,
  getSupplierOptionLabel,
} from '@odo/helpers/attributes';

const SupplierOption = ({ supplier }: { supplier: SupplierOptionType }) => (
  <Flex color="inherit">{getSupplierOptionLabel(supplier)}</Flex>
);

const SupplierSelector = ({
  label,
  placeholder,
  closeOnSelect,
  supplier,
  setSupplier,
  removeSupplier,
  matchRPStyles,
}: {
  label: string;
  placeholder?: string;
  closeOnSelect?: boolean;
  supplier?: string;
  // NOTE: these two are props for now so we can integrate with RP.
  // in future we might choose to do this from within this component itself.
  setSupplier: (supplier: SupplierOptionType) => void;
  removeSupplier: (supplier: SupplierOptionType) => void;
  matchRPStyles?: boolean;
}) => {
  const supplierOptions = useAttributeOptions(AttributeCode.supplier);

  const [results, setResults] = useState<SupplierOptionType[]>([]);

  const selectedSupplier: SupplierOptionType | undefined = useMemo(() => {
    if (!supplier || supplierOptions.length === 0) return undefined;
    const matchingOption = supplierOptions.find(({ key }) => key === supplier);
    if (matchingOption) return attributeToSupplierOption(matchingOption);
    return undefined;
  }, [supplier, supplierOptions]);

  const onSearch = useCallback(
    (term: string) => {
      setResults(
        supplierOptions
          .filter(supplier => {
            if (
              // NOTE: the actual supplier name is only in the original data due to poor structuring
              supplier.originalData.value
                .toLowerCase()
                .indexOf(term.toLowerCase()) !== -1
            ) {
              return true;
            }
            // NOTE: if we wanted we could try match on buyer name, trading name, or contact details
            return false;
          })
          .map(attributeToSupplierOption)
      );
    },
    [supplierOptions]
  );

  return (
    <SearchableDropdown<SupplierOptionType>
      // searching
      onSearch={onSearch}
      options={results}
      renderOption={option => <SupplierOption supplier={option} />}
      onSelect={setSupplier}
      // selected options
      selectedOptions={[...(selectedSupplier ? [selectedSupplier] : [])]}
      renderSelectedOption={option => <SupplierOption supplier={option} />}
      onRemove={removeSupplier}
      // basics
      label={label}
      placeholder={placeholder}
      closeOnSelect={closeOnSelect}
      matchRPStyles={matchRPStyles}
      required
    />
  );
};

export default SupplierSelector;
