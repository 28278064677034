import { cssColor } from '@odo/utils/css-color';
import Button from '@odo/components/elements/button';
import { FaCopy as IconDuplicate } from 'react-icons/fa';
import {
  useCurrentProduct,
  useProductEditor,
} from '@odo/contexts/product-editor';
import { useHasChanges } from '@odo/screens/deal/editor/hooks';
import { useCallback, useState } from 'react';
import Dialog from '@odo/components/widgets/dialog';
import Duplicate from '@odo/components/widgets/duplicate';
import { useAttributeContext } from '@odo/hooks/attributes';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import { Flex } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';

/**
 * The duplicate button here has extra code for handling unsaved changes.
 * This isn't necessary in the core duplication widget, as it doesn't have access to the product editor context.
 * And there will be scenarios where it will receive a product that couldn't have unsaved changes.
 */
const DuplicateButton = () => {
  const hasChanges = useHasChanges();
  const currentProduct = useCurrentProduct();

  const { reset: resetProduct } = useProductEditor();
  const { editorCustomOptions, clearActions: resetCustomOptions } =
    useCustomOptionsEditorContext();

  const { isReady: areAttributesLoaded, attributes } = useAttributeContext();

  const [duplicateDeal, setDuplicateDeal] = useState(false);

  const resetDeal = useCallback(() => {
    resetProduct();
    resetCustomOptions();
  }, [resetProduct, resetCustomOptions]);

  return (
    <>
      {!!currentProduct?.id && (
        <Button
          hue="grey"
          variant="flat"
          circular
          px={1}
          py={1}
          onClick={() => setDuplicateDeal(true)}
        >
          <IconDuplicate size={16} color={cssColor('grey')} />
        </Button>
      )}

      <Dialog
        title="Duplicate Deal"
        isOpen={duplicateDeal}
        close={() => setDuplicateDeal(false)}
      >
        {hasChanges ? (
          <Flex flexDirection="column" gap={[3, 4]}>
            <Flex flexDirection="column" alignItems="center" gap={[2, 3]}>
              <IconExclamation
                color={cssColor('palette-yellow')}
                width={42}
                height={42}
              />

              <Heading fontSize={[3, 4]}>You have unsaved changes!</Heading>

              <Text textAlign="center">
                You can't keep these when duplicating.
                <br />
                Either cancel duplication and save your changes, or reset them
                to continue.
              </Text>
            </Flex>

            <Flex justifyContent="space-between" gap={3}>
              <Button
                hue="grey"
                variant="flat"
                onClick={() => setDuplicateDeal(false)}
              >
                Cancel
              </Button>

              <Button hue="yellow" variant="solid" onClick={() => resetDeal()}>
                Reset Changes and Continue
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Duplicate
            product={currentProduct}
            customOptions={editorCustomOptions}
            areAttributesLoaded={areAttributesLoaded}
            attributes={attributes}
            close={() => setDuplicateDeal(false)}
          />
        )}
      </Dialog>
    </>
  );
};

export default DuplicateButton;
