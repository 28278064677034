import type { EditorProductInterface } from '@odo/types/portal';
import validateSku from '@odo/data/product/validate-sku';
import { mutationCreateProduct } from '@odo/graphql/product-new';
import { editorProductToCreateProductInput } from '@odo/transformers/product';
import { uploadAllNewImages } from '@odo/data/product/images';
import { error } from '@odo/utils/toast';
import { downloadImage } from '@odo/screens/deal/editor/images-and-videos/helpers';
import { editorSizeInfoToCreateSizeChartInput } from '@odo/transformers/size-chart';

const createProduct = async ({
  product: draftProduct,
  signal,
}: {
  product: EditorProductInterface;
  signal?: AbortSignal;
}) => {
  if (!draftProduct.isNew) {
    throw new Error('Cannot perform create operation on an existing product.');
  }

  if (!draftProduct.sku) {
    throw new Error('Product SKU is required.');
  }

  if (draftProduct.sku) {
    await validateSku({ sku: draftProduct.sku });
  }

  // our size chart images need to be parsed, but our input transformer isn't async
  // so quickly prep them here and pass them through
  const sizeChartInput = await editorSizeInfoToCreateSizeChartInput(
    draftProduct.sizeInfo
  );

  // transform data for create mutation
  const createProductInput = editorProductToCreateProductInput(
    draftProduct,
    sizeChartInput
  );
  if (!createProductInput) {
    throw new Error('Failed to transform product data for create.');
  }

  // make the create product API call
  const createdProduct = await mutationCreateProduct({
    product: createProductInput,
    signal,
  });
  if (!createdProduct || !createdProduct.id) {
    throw new Error('Create product mutation failed.');
  }

  // upload any new images that were added
  const { imageUploadSuccesses, imageUploadFailures } =
    await uploadAllNewImages({
      id: createdProduct.id,
      images: draftProduct.images,
    });

  // add new images to product data
  if (imageUploadSuccesses.length > 0) {
    imageUploadSuccesses.forEach(createdImage => {
      if (!createdProduct.images) {
        createdProduct.images = [];
      }

      createdProduct.images.push(createdImage);
    });
  }

  // show a toast with a download button for each image that failed to upload
  // TODO: BP-775: keep new images that failed to upload
  if (imageUploadFailures.length > 0) {
    imageUploadFailures.forEach(failure =>
      error(`Failed to upload image: "${failure.message}"`, {
        messageOptions: {
          action: {
            label: 'Download image to try again',
            callback: () => downloadImage(failure.image),
          },
        },
      })
    );
  }

  // return our resulting/created product data
  return createdProduct;
};

export default createProduct;
