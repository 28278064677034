import React from 'react';
import { RPSCard } from '@rps/web-components/build/react-wrappers';
import { Deal } from '../../../models/Deal.jsx';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { DealBuyerAndSupplier } from 'models/BuyerAndSupplier.jsx';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { Select } from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';
import { removedItems, excludedBuyers } from '@odo/constants/buyers';

export const BuyerSection = () => {
  const currentDeal = useCurrentDealSource();
  const { deal } = currentDeal;

  const buyerOptions = useAttributeOptions(AttributeCode.buyer);
  const salesAssistantOptions = useAttributeOptions(
    AttributeCode.salesAssistant
  );
  const priorityOptions = useAttributeOptions(AttributeCode.priority);

  return (
    <RPSCard css=":host { width: 100%; }">
      <div slot="header">
        <h5>Buyer</h5>
      </div>

      <Select
        label="Account Manager"
        value={deal.buyerAndSupplier.buyer}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.BUYER_AND_SUPPLIER,
          field: DealBuyerAndSupplier.FIELDS.BUYER,
        })}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...buyerOptions
            .filter(
              option =>
                option.value === deal.buyerAndSupplier.buyer ||
                (!removedItems.includes(option.value) &&
                  !excludedBuyers.includes(option.value))
            )
            .sort((a, b) => a.value.localeCompare(b.value))
            .map(option => ({
              id: option.value,
              value: option.value,
              label: option.label,
              disabled: excludedBuyers.includes(option.value),
            })),
        ]}
        required
        matchRPStyles
      />
      <Select
        label="Loader"
        value={deal.buyerAndSupplier.salesAssistant}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.BUYER_AND_SUPPLIER,
          field: DealBuyerAndSupplier.FIELDS.SALES_ASSISTANT,
        })}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...salesAssistantOptions
            .filter(option => option.value.toLowerCase() !== 'none')
            .sort((a, b) => a.value.localeCompare(b.value))
            .map(option => ({
              id: option.value,
              value: option.value,
              label: option.label,
            })),
        ]}
        required
        matchRPStyles
      />
      <Select
        label="Priority"
        value={deal.buyerAndSupplier.priority?.toString()}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.BUYER_AND_SUPPLIER,
          field: DealBuyerAndSupplier.FIELDS.PRIORITY,
        })}
        options={[
          ...priorityOptions
            .filter(option => option.value.toLowerCase() !== 'none')
            .map(option => ({
              id: option.value,
              /**
               * NOTE: almost all attributes use the "attribute.key" for their value from "getProduct"
               * but priority uses the "attribute.value"
               * in our attribute context we stopped even using the value returned by the API coz it seemed useless
               * fortunately we kept the original API data, so we can use that here
               */
              value: option.originalData.value,
              label: option.label,
            })),
        ]}
        required
        matchRPStyles
      />
    </RPSCard>
  );
};
