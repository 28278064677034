import { EMPTY_PRODUCT } from '@odo/contexts/product-editor/constants';
import { createContext } from 'react';
import type { ChangeInput, ProductEditorContextType } from './types';

const defaultProductEditorContext: ProductEditorContextType = {
  currentProduct: EMPTY_PRODUCT,
  change: (_change: ChangeInput) => ({ undo: () => void 0 }),
  canReset: false,
  hasChanges: false,
  reset: () => void 0,
  getChanges: () => [],
  resetEditor: () => void 0,
  setLock: (
    _status: boolean,
    _options?: { reason?: string; withNotice?: boolean }
  ) => void 0,
  addEventListener: _event => void 0,
  removeEventListener: _event => void 0,
};

const ProductEditorContext = createContext<ProductEditorContextType>(
  defaultProductEditorContext
);

export default ProductEditorContext;
