import type { DialogType } from '@odo/components/widgets/dialog/elements/types';
import { createPortal } from 'react-dom';
import styled from '@odo/lib/styled';
import { useEffect, useRef, useState } from 'react';
import DialogInner from '@odo/components/widgets/dialog/elements/inner';

interface NavigatorUAData {
  brands: Array<{ brand: string; version: string }>;
  mobile: boolean;
  platform: string;
}

type NavigatorWithUAData = Navigator & {
  userAgentData: NavigatorUAData;
};

type WindowNavigator = Navigator | NavigatorWithUAData;

const isNavigatorUserAgent = (
  navigator: WindowNavigator
): navigator is NavigatorWithUAData =>
  typeof (navigator as NavigatorWithUAData).userAgentData !== 'undefined';

/**
 * These APIs are deprecated and/or not super trustworthy.
 * But there's no way to detect lack of support for `overflow: hidden` on document.body
 * So we're gonna rely on these for now.
 * If they do fail, the impacts are not so drastic as to be breaking.
 */
const getPlatform = () =>
  isNavigatorUserAgent(window.navigator)
    ? window.navigator.userAgentData?.platform
    : navigator.platform;

const DialogBackdrop = styled.div`
  position: fixed;
  z-index: 75;
  inset: 0;
  background-color: hsl(226deg 25% 12% / 30%);
  /* TODO: I'd love to have a blur effect here, but this CSS3 blur has a huge performance impact */
  /* let's look into using some kind of blurry BG image instead later */
  /* backdrop-filter: blur(2px); */
  display: grid;
  place-content: center;

  transition: opacity 75ms ease;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: unset;
  }
`;

const DialogPortal = ({ children, isOpen, close, ...rest }: DialogType) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  const dialogInnerRef = useRef<HTMLDivElement>(null);

  const [internalOpen, setInternalOpen] = useState(false);

  /**
   * We use a second piece of state to activate our animation after rendering.
   *
   * NOTE: tbh, I'm not sure this is the best approach, but I tried useDeferredValue,
   * and it seemed to actually be slower than this, so for now leaving it as is.
   */
  useEffect(() => {
    if (internalOpen === isOpen) return;
    setInternalOpen(isOpen);
  }, [isOpen, internalOpen]);

  /**
   * Disable scroll.
   *
   * Stolen from Floating UIs FloatingOverlay element.
   * Main difference being that we only ever render ONE overlay, so we don't need to check for locks.
   * @see https://github.com/floating-ui/floating-ui/blob/master/packages/react/src/components/FloatingOverlay.tsx
   */
  useEffect(() => {
    if (!isOpen) return;

    const isIOS = /iP(hone|ad|od)|iOS/.test(getPlatform());
    const bodyStyle = document.body.style;
    // RTL <body> scrollbar
    const scrollbarX =
      Math.round(document.documentElement.getBoundingClientRect().left) +
      document.documentElement.scrollLeft;
    const paddingProp = scrollbarX ? 'paddingLeft' : 'paddingRight';
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    const scrollX = bodyStyle.left
      ? parseFloat(bodyStyle.left)
      : window.scrollX;
    const scrollY = bodyStyle.top ? parseFloat(bodyStyle.top) : window.scrollY;

    bodyStyle.overflow = 'hidden';

    if (scrollbarWidth) {
      bodyStyle[paddingProp] = `${scrollbarWidth}px`;
    }

    // Only iOS doesn't respect `overflow: hidden` on document.body, and this
    // technique has fewer side effects.
    if (isIOS) {
      // iOS 12 does not support `visualViewport`.
      const offsetLeft = window.visualViewport?.offsetLeft || 0;
      const offsetTop = window.visualViewport?.offsetTop || 0;

      Object.assign(bodyStyle, {
        position: 'fixed',
        top: `${-(scrollY - Math.floor(offsetTop))}px`,
        left: `${-(scrollX - Math.floor(offsetLeft))}px`,
        right: '0',
      });
    }

    return () => {
      Object.assign(bodyStyle, {
        overflow: '',
        [paddingProp]: '',
      });

      if (isIOS) {
        Object.assign(bodyStyle, {
          position: '',
          top: '',
          left: '',
          right: '',
        });
        window.scrollTo(scrollX, scrollY);
      }
    };
  }, [isOpen]);

  /**
   * Close on backdrop click
   */
  useEffect(() => {
    if (!isOpen || !backdropRef.current || !dialogInnerRef.current) {
      return;
    }

    const outsideClickClose = (e: MouseEvent | TouchEvent) => {
      if (
        dialogInnerRef.current &&
        e.target instanceof Element &&
        !dialogInnerRef.current.contains(e.target)
      ) {
        close();
      }
    };

    const backdrop = backdropRef.current;
    backdrop.addEventListener('mousedown', outsideClickClose);
    backdrop.addEventListener('touchstart', outsideClickClose);

    return () => {
      backdrop.removeEventListener('mousedown', outsideClickClose);
      backdrop.removeEventListener('touchstart', outsideClickClose);
    };
  }, [isOpen, close]);

  /**
   * Make the rest of the page inert while the dialog is open.
   */
  useEffect(() => {
    if (!isOpen) return;

    const root = document.getElementById('root');

    if (!root) return;

    root.setAttribute('inert', '');
    root.setAttribute('aria-hidden', 'true');

    return () => {
      root.removeAttribute('inert');
      root.removeAttribute('aria-hidden');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <DialogBackdrop
      ref={backdropRef}
      className={internalOpen ? 'active' : undefined}
    >
      <div ref={dialogInnerRef}>
        <DialogInner isOpen={isOpen} close={close} {...rest}>
          {children}
        </DialogInner>
      </div>
    </DialogBackdrop>,
    document.body
  );
};

export default DialogPortal;
