import type { CustomOptionTreeValue } from '@odo/types/portal';
import { debounce } from '@odo/utils/debounce';
import uuid from '@odo/utils/uuid';
import { useContext, useMemo } from 'react';
import CustomOptionsEditorContext from './context';

export const useCustomOptionsEditorContext = () =>
  useContext(CustomOptionsEditorContext);

export const useAddAction = () => {
  const { addAction } = useCustomOptionsEditorContext();
  return addAction;
};

export const useAddActionDebounced = (wait = 250) => {
  const addAction = useAddAction();
  const debounced = useMemo(() => debounce(addAction, wait), [addAction, wait]);
  return debounced;
};

export const useShowErrors = () => {
  const { showErrors } = useCustomOptionsEditorContext();
  return showErrors;
};

export const useCopyPaste = () => {
  const { copyingOptionId, copyOption, cancelCopy, pasteOption } =
    useCustomOptionsEditorContext();
  return { copyingOptionId, copyOption, cancelCopy, pasteOption };
};

export const useAutoSumEnabled = () => {
  const { autoSumEnabled } = useCustomOptionsEditorContext();
  return autoSumEnabled;
};

interface VariationLabelPart {
  option: string;
  value: string;
}

interface Variation {
  id: string; // NOTE: uuid for comparisons
  label: VariationLabelPart[];
  sku: string;
  qty: number;
  isUniqueSKU?: boolean;
}

const addVariation = ({
  optionTitle,
  value,
  baseLabel,
  baseSku,
  variations,
}: {
  optionTitle: string;
  value: CustomOptionTreeValue;
  baseLabel: VariationLabelPart[];
  baseSku: string;
  variations: Variation[];
}) => {
  const nextLabel = [...baseLabel, { option: optionTitle, value: value.title }];
  const nextSku = `${baseSku}-${value.sku}`;

  if (value.childOptions.length > 0) {
    value.childOptions.forEach(childOption =>
      childOption.values.forEach(childValue =>
        addVariation({
          optionTitle: childOption.title,
          value: childValue,
          baseLabel: nextLabel,
          baseSku: nextSku,
          variations,
        })
      )
    );
  } else {
    variations.push({
      id: uuid(),
      label: nextLabel,
      sku: nextSku,
      qty: value.quantity.number || 0,
    });
  }
};

export const useVariationList = (baseSku: string) => {
  const { editorCustomOptions } = useCustomOptionsEditorContext();

  const variationsDraft: Omit<Variation, 'isUnique'>[] = [];

  editorCustomOptions.forEach(option =>
    option.values.forEach(value =>
      addVariation({
        optionTitle: option.title,
        value,
        baseLabel: [],
        baseSku,
        variations: variationsDraft,
      })
    )
  );

  // NOTE: we need to first generate all the variations before we can check if their SKUs are unique.
  const variations: Variation[] = variationsDraft.map(variation => ({
    ...variation,
    isUniqueSKU: !variationsDraft.some(
      v => v.id !== variation.id && v.sku === variation.sku
    ),
  }));

  return variations;
};
